import { DynamicService } from 'utils/service';
import {
  ActivityItem,
  API_USER_PATIENT_PROFILE_ACTIVITIES,
  GetActivitiesInput,
  UserPatientProfileActivity,
  UserPatientProfileActivityLastWeightInfo,
  UserPatientProfileActivityPostInput,
} from './models';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import * as dynamic from 'utils/dynamic-helpers';

export * from './models';

class Service extends DynamicService<UserPatientProfileActivity, 'id'> {
  async getActivities(input: GetActivitiesInput) {
    const { userPatientProfileID, dateRange } = input;

    const {
      data: { value },
    } = await this.getAllDynamic<ActivityItem>({
      filter: dynamic
        .mergeFilters(
          `userPatientProfileID=="${userPatientProfileID}"`,
          dynamic.createFilterDateISO('entryDate', dateRange),
        )
        .join('&&'),
      select: dynamic.select(
        'id',
        'entryDate',
        'remarks',
        'userEmployeeProfile.fullName as employee',
      ),
      orderBy: 'entryDate desc',
      take: 20,
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.remarks,
      date: String(item.entryDate),
      employee: item.employee,
      download: null,
    }));
  }
}

export const ServiceUserPatientProfileActivities = new Service({
  getAll: API_USER_PATIENT_PROFILE_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_ACTIVITIES.POST,
  patch: API_USER_PATIENT_PROFILE_ACTIVITIES.PATCH,
  delete: API_USER_PATIENT_PROFILE_ACTIVITIES.DELETE,
});

export const apiUserPatientProfileActivities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserPatientProfileActivityWeight: build.query<
      null | UserPatientProfileActivityLastWeightInfo,
      string
    >({
      queryFn: async (userPatientProfileID) => {
        try {
          const {
            data: { value },
          } = await ServiceUserPatientProfileActivities.getAllDynamic<UserPatientProfileActivityLastWeightInfo>(
            {
              filter: dynamic
                .mergeFilters(
                  dynamic.createFilterEquals('userPatientProfileID', userPatientProfileID),
                  dynamic.createFilterContains('changes', '"field":"weight"'),
                )
                .join('&&'),
              select: dynamic.select(
                'entryDate',
                'changes',
                'remarks',
                'new { userEmployeeProfile.fullName } as userEmployeeProfile',
              ),
              orderBy: 'entryDate desc',
              take: 1,
            },
          );

          return { data: value[0] ?? null };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, userPatientProfileID) => [
        { type: RTK_TAGS.USER_PATIENT_PROFILE_ACTIVITIES, id: `id__${userPatientProfileID}` },
      ],
    }),
    postUserPatientProfileActivity: build.mutation({
      queryFn: async (input: UserPatientProfileActivityPostInput) => {
        try {
          const res = await ServiceUserPatientProfileActivities.post({
            ...input,
            entryDate: new Date().toISOString(),
          });
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, { userPatientProfileID }) => [
        { type: RTK_TAGS.USER_PATIENT_PROFILE_ACTIVITIES, id: `id__${userPatientProfileID}` },
      ],
    }),
  }),
});
