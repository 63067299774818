import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { BaseCreateComponentProps } from '../../models';
import { useEffectError } from 'hooks';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { useTranslate } from 'hooks/use-translate';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { apiPdfContent } from 'services/pdf-content';
import { isMutationFulfilled } from 'utils/rtk-query';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';
import { apiPdf } from 'services/pdf';
import { Stack } from 'components/stack';

const useLazyFetchPatientQuery =
  apiUserPatientProfile.useLazyGetPatientDetailsForPdfEmptyLetterQuery;

const useGenerateContentMutation = apiPdfContent.useGenerateContentEmptyLetterMutation;
const useGenerateDocMutation = apiPdf.useGenerateDocumentEmptyLetterMutation;

type ApplyState = { title: string; content: string };
interface DialogCreateContentProps {
  userPatientProfileID: string;
  onClose: () => void;
  onApply: (state: ApplyState) => void;
}
const DialogCreateContent = memo<DialogCreateContentProps>(
  ({ userPatientProfileID, onClose, onApply }) => {
    const { tp } = useTranslate();
    const languageID = useAppSelector(selectLanguageID);

    const [getPatient, resultPatient] = useLazyFetchPatientQuery();
    useEffectError(resultPatient.error);

    const [generateContent, resultGenerate] = useGenerateContentMutation();
    useEffectError(resultGenerate.error);

    const isLoading = resultPatient.isLoading || resultGenerate.isLoading;

    const performSubmit = useCallback(async () => {
      const responsePatient = await getPatient(userPatientProfileID);

      if (!responsePatient.data) return;

      const res = await generateContent({
        languageID,
        payload: {
          patient: responsePatient.data,
        },
      });

      if (isMutationFulfilled(res)) {
        onApply({ content: res.data.content, title: res.data.title });
      }
    }, [getPatient, generateContent, languageID, onApply, userPatientProfileID]);

    const refOnce = useRef(false);

    useEffect(() => {
      if (refOnce.current) return;
      performSubmit();
      refOnce.current = true;
    }, [performSubmit]);

    return (
      <Dialog open={true} fullWidth maxWidth={'sm'} onClose={onClose}>
        <DialogHeading isLoading={isLoading} title={tp('loading')} onClose={onClose} />
        <DialogContent>
          <Stack minHeight={'10rem'} alignItems={'center'} justifyContent={'center'}>
            <Typography variant={'h3'} color={'primary'} align={'center'}>
              {tp('collect-the-data')}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  },
);

export const DialogCreateDocEmptyLetter = memo<BaseCreateComponentProps>(
  ({ userPatientProfileID, onClose, onDone }) => {
    const [state, setState] = useState<ApplyState>();

    const [generateDoc, resultGenerateDoc] = useGenerateDocMutation();
    useEffectError(resultGenerateDoc.error);

    const isLoading = resultGenerateDoc.isLoading;

    const onGenerate = useCallback(
      async (content: string) => {
        if (!state) return;

        await generateDoc({ userPatientProfileID, content, title: state.title });

        onDone();
      },
      [state, generateDoc, onDone, userPatientProfileID],
    );

    if (!state) {
      return (
        <DialogCreateContent
          userPatientProfileID={userPatientProfileID}
          onClose={onClose}
          onApply={setState}
        />
      );
    }

    return (
      <DialogEditHtmlContent
        isLoading={isLoading}
        title={state.title}
        content={state.content}
        onCancel={onClose}
        onGenerate={onGenerate}
      />
    );
  },
);
