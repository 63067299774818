import React, { useCallback, useMemo } from 'react';
import {
  useFactoryChangeInput,
  useFactoryChangeSelect,
  usePatientData,
} from 'components/dialog-patient/hooks';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import {
  schemaUserPatientProfile,
  UserPatientProfileEditGeneralInput,
} from 'services/user-patient-profile';
import { useTranslate } from 'hooks/use-translate';
import { useFieldProps } from 'hooks';
import {
  useSourceCities,
  useSourceDietitians,
  useSourceGenders,
  useSourceHMOs,
  useSourceProfilesSupport,
} from 'components/hooks';
import style from './index.module.scss';
import { RowField } from 'components/row-field';
import { AppInput } from 'components/app-input';
import { composeFunctions } from 'utils';
import { AppSelect } from 'components/app-select';
import { AppPhoneInput } from 'components/app-phone-input';
import { Box } from '@material-ui/core';
import { AppCheckbox } from 'components/app-checkbox';
import { SelectEmployee } from 'components/select-employee';
import { InferType, ObjectSchema } from 'yup';
import { useAsyncMemo } from 'hooks/use-async-memo';
import { AppIdNumberInput } from 'components/app-id-number-input';
import { getSchemaFiledMaxLength } from 'utils/schema';

type Model = Pick<
  InferType<typeof schemaUserPatientProfile>,
  'cityID' | 'idNumber' | 'genderID' | 'mobilePhone' | 'email' | 'shortRemark' | 'hmoID'
>;
interface EditableFormProps {
  schema: ObjectSchema<Model>;
  isLoading: boolean;
  showDietitian: boolean;
}
export const GeneralForm: React.FC<EditableFormProps> = ({ isLoading, showDietitian, schema }) => {
  const { patient, onUpdate } = usePatientData();
  const { control, errors, watch, getValues, trigger } =
    useFormContext<UserPatientProfileEditGeneralInput>();
  const { t } = useTranslate();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '', disabled: isLoading });

  const factoryPayload = { initData: patient, getValues, onUpdate, validate: trigger };

  const factoryChangeInput = useFactoryChangeInput(factoryPayload);
  const factoryChangeSelect = useFactoryChangeSelect(factoryPayload);

  const sourceGenders = useSourceGenders();
  const sourceCities = useSourceCities();
  const sourceHMOs = useSourceHMOs();
  const sourceDietitians = useSourceDietitians();
  const sourceProfilesSupport = useSourceProfilesSupport();

  const userEmployeeProfileSupportSecondaryID = watch('userEmployeeProfileSupportSecondaryID');
  const optionsPrimary = useMemo(() => {
    return sourceProfilesSupport.data.filter(
      (employee) => employee.id !== userEmployeeProfileSupportSecondaryID,
    );
  }, [sourceProfilesSupport.data, userEmployeeProfileSupportSecondaryID]);

  const userEmployeeProfileSupportPrimaryID = watch('userEmployeeProfileSupportPrimaryID');
  const optionsSecondary = useMemo(() => {
    return sourceProfilesSupport.data.filter(
      (employee) => employee.id !== userEmployeeProfileSupportPrimaryID,
    );
  }, [sourceProfilesSupport.data, userEmployeeProfileSupportPrimaryID]);

  const validateField = useCallback(
    async (name: keyof Model) => {
      if (!patient) {
        return false;
      }
      try {
        await schema.validateAt(name, patient);
        return true;
      } catch (e) {
        return false;
      }
    },
    [patient, schema],
  );
  const isValidCity = useAsyncMemo(() => validateField('cityID'), [validateField]);

  const readOnlyMap = useMemo<Record<string, boolean>>(() => {
    return {
      idNumber: !!patient?.idNumber,
      genderID: !!patient?.genderID,
      hmoID: !!patient?.hmoID,
      cityID: Boolean(isValidCity),
      mobilePhone: !!patient?.mobilePhone,
      email: !!patient?.email,
      address: !!patient?.address,
      shortRemark: !!patient?.shortRemark,
      userEmployeeProfileDietitianID: !!patient?.userEmployeeProfileDietitianID,
      userEmployeeProfileSupportPrimaryID: !!patient?.userEmployeeProfileSupportPrimaryID,
      userEmployeeProfileSupportSecondaryID: !!patient?.userEmployeeProfileSupportSecondaryID,
    };
  }, [patient, isValidCity]);

  const getInputFieldProps = useCallback(
    (renderProps: ControllerRenderProps<any>) => {
      const inReadOnly = readOnlyMap[renderProps.name];

      return {
        ...getFieldProps(renderProps),
        InputProps: {
          readOnly: inReadOnly,
        },
        disableClearable: inReadOnly ? true : undefined,
      };
    },
    [getFieldProps, readOnlyMap],
  );

  const getMaxLength = useCallback(
    (key: keyof InferType<typeof schema>) => {
      if (!key) return undefined;
      return getSchemaFiledMaxLength(schema, key);
    },
    [schema],
  );
  const getSelectFieldProps = useCallback(
    (renderProps: ControllerRenderProps<Model>) => {
      const inReadOnly = readOnlyMap[renderProps.name];
      const maxLength = getMaxLength(renderProps.name as keyof Model);
      return {
        ...getFieldProps(renderProps),
        readOnly: inReadOnly,
        inputProps: {
          maxLength,
        },
      };
    },
    [getFieldProps, readOnlyMap, getMaxLength],
  );

  const rowFieldClasses = useMemo(() => {
    return { root: style.row, label: style.rowLabel, value: style.rowValue };
  }, []);

  return (
    <div className={style.dataEdit}>
      <Controller
        control={control}
        name={'idNumber'}
        render={(renderProps) => {
          const { label, ...rest } = getInputFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppIdNumberInput
                {...rest}
                onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                disableClearable
              />
            </RowField>
          );
        }}
      />
      <Controller
        control={control}
        name={'genderID'}
        render={(renderProps) => {
          const { label, ...rest } = getSelectFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppSelect
                {...rest}
                onChange={composeFunctions(
                  rest.onChange,
                  factoryChangeSelect(rest.name, label, sourceGenders.map),
                )}
                options={sourceGenders.data}
                loading={sourceGenders.loading}
                disableClearable
              />
            </RowField>
          );
        }}
      />
      <Controller
        control={control}
        name={'cityID'}
        render={(renderProps) => {
          const { label, ...rest } = getSelectFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppSelect
                {...rest}
                onChange={composeFunctions(
                  rest.onChange,
                  factoryChangeSelect(rest.name, label, sourceCities.map),
                )}
                options={sourceCities.data}
                loading={sourceCities.loading}
                disableClearable
              />
            </RowField>
          );
        }}
      />
      <Controller
        control={control}
        name={'address'}
        render={(renderProps) => {
          const { label, ...rest } = getInputFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppInput
                {...rest}
                onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
                disableClearable
              />
            </RowField>
          );
        }}
      />
      <Controller
        name={'mobilePhone'}
        control={control}
        render={(renderProps) => {
          const { label, ...rest } = getInputFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppPhoneInput
                {...rest}
                onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
              />
            </RowField>
          );
        }}
      />
      <Controller
        name={'email'}
        control={control}
        render={(renderProps) => {
          const { label, ...rest } = getInputFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppInput
                {...rest}
                type={'email'}
                disableClearable
                onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
              />
            </RowField>
          );
        }}
      />
      <Controller
        control={control}
        name={'hmoID'}
        render={(renderProps) => {
          const { label, ...rest } = getSelectFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppSelect
                {...rest}
                onChange={composeFunctions(
                  rest.onChange,
                  factoryChangeSelect(rest.name, label, sourceHMOs.map),
                )}
                options={sourceHMOs.data}
                loading={sourceHMOs.loading}
                disableClearable
              />
            </RowField>
          );
        }}
      />
      <Controller
        name={'isTLC'}
        control={control}
        render={(renderProps) => {
          const { label, ...rest } = getFieldProps(renderProps);
          const visibleLabel = t('tlc');
          return (
            <RowField classes={rowFieldClasses} label={visibleLabel}>
              <Box ml={'-1.1rem'}>
                <AppCheckbox
                  {...rest}
                  onChange={composeFunctions(
                    rest.onChange,
                    factoryChangeInput(rest.name, visibleLabel),
                  )}
                />
              </Box>
            </RowField>
          );
        }}
      />
      <Controller
        name={'shortRemark'}
        control={control}
        render={(renderProps) => {
          const { label, ...rest } = getInputFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <AppInput
                {...rest}
                onChange={composeFunctions(rest.onChange, factoryChangeInput(rest.name, label))}
              />
            </RowField>
          );
        }}
      />
      {showDietitian && (
        <Controller
          control={control}
          name={'userEmployeeProfileDietitianID'}
          render={(renderProps) => {
            const { label, ...rest } = getSelectFieldProps(renderProps);
            return (
              <RowField classes={rowFieldClasses} label={label}>
                <SelectEmployee
                  {...rest}
                  onChange={composeFunctions(
                    rest.onChange,
                    factoryChangeSelect(rest.name, label, sourceDietitians.map),
                  )}
                  options={sourceDietitians.data}
                  loading={sourceDietitians.loading}
                />
              </RowField>
            );
          }}
        />
      )}
      <Controller
        control={control}
        name={'userEmployeeProfileSupportPrimaryID'}
        render={(renderProps) => {
          const { label, ...rest } = getSelectFieldProps(renderProps);
          return (
            <RowField classes={rowFieldClasses} label={label}>
              <SelectEmployee
                {...rest}
                options={optionsPrimary}
                onChange={composeFunctions(
                  rest.onChange,
                  factoryChangeSelect(rest.name, label, sourceProfilesSupport.map),
                )}
                loading={sourceProfilesSupport.loading}
              />
            </RowField>
          );
        }}
      />
      <Controller
        control={control}
        name={'userEmployeeProfileSupportSecondaryID'}
        render={(renderProps) => {
          const { label, ...rest } = getSelectFieldProps(renderProps);

          return (
            <RowField classes={rowFieldClasses} label={label}>
              <SelectEmployee
                {...rest}
                onChange={composeFunctions(
                  rest.onChange,
                  factoryChangeSelect(rest.name, label, sourceProfilesSupport.map),
                )}
                options={optionsSecondary}
                loading={sourceProfilesSupport.loading}
              />
            </RowField>
          );
        }}
      />
    </div>
  );
};
