const API_ENDPOINT = 'Hmos';

export const API_HMOS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Hmo, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Hmo, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface Hmo extends Components.Schemas.Hmo {}
