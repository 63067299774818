import React, { memo, useCallback, useMemo, useState } from 'react';
import { BaseCreateComponentProps } from '../../models';
import { useEffectError, useFieldProps } from 'hooks';
import { apiUserPatientProfile, schemaUserPatientProfile } from 'services/user-patient-profile';
import { convertToDate, dateFormat } from 'utils/dates';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { useTranslate } from 'hooks/use-translate';
import { Controller, useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { AppPrescriptionDrugsAreaInput } from 'components/app-prescription-drugs-area-input';
import { AppChipAreaInput } from 'components/app-chip-area-input';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import * as yup from 'yup';
import { AppPassportNumberInput } from 'components/app-pasport-number-input';
import { AppInput } from 'components/app-input';
import { REGEXP_PATIENT_PASSPORT_NUMBER } from 'utils/regexp';
import { apiPdfContent } from 'services/pdf-content';
import { isMutationFulfilled } from 'utils/rtk-query';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';
import { apiPdf } from 'services/pdf';

const schema = schemaUserPatientProfile.pick(['passportNumber', 'nameEng']).shape({
  nameEng: yup.string().nullable().notRequired().required('rule-required').trim().default(''),
  passportNumber: yup
    .string()
    .required('rule-required')
    .matches(REGEXP_PATIENT_PASSPORT_NUMBER, 'rule-passport-number')
    .max(10, 'rule-max-length')
    .default(''),
  drugs: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        title: yup.string(),
      }),
    )
    .min(1, 'rule-required')
    .default([]),
});

type FormModel = yup.InferType<typeof schema>;

const usePatchMutation = apiUserPatientProfile.usePatchPatientWithLogMutation;
const useFetchPatientQuery = apiUserPatientProfile.useGetPatientForGeneralFormQuery;
const useFetchPrescriptionsQuery =
  apiUserPatientPrescriptions.useGetMedicalPrescriptionsWithDrugsQuery;

const useGenerateContentMutation = apiPdfContent.useGenerateContentTravelLetterMutation;
const useGenerateDocMutation = apiPdf.useGenerateDocumentTravelLetterMutation;

type ApplyState = { title: string; content: string; formData: FormModel };
interface DialogCreateContentProps {
  userPatientProfileID: string;
  onClose: () => void;
  onApply: (state: ApplyState) => void;
}
const DialogCreateContent = memo<DialogCreateContentProps>(
  ({ userPatientProfileID, onClose, onApply }) => {
    const { tp, t } = useTranslate();
    const languageID = useAppSelector(selectLanguageID);

    const resultPatient = useFetchPatientQuery(userPatientProfileID);
    useEffectError(resultPatient.error);

    const [generateContent, resultGenerate] = useGenerateContentMutation();
    useEffectError(resultGenerate.error);

    const methods = useForm({
      defaultValues: schema.cast({ ...resultPatient.data }, { assert: false }),
      resolver: yupResolver(schema),
    });
    const { errors, control, handleSubmit } = methods;
    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    const sourcePrescriptions = useFetchPrescriptionsQuery(userPatientProfileID);
    useEffectError(sourcePrescriptions.error);

    const prescriptionOptions = useMemo(() => {
      return (sourcePrescriptions.data || []).map((prescription) => {
        return {
          id: String(prescription.id),
          title: dateFormat(prescription.entryDate, APP_FORMAT_DATE_TIME),
          isCompleted: !!prescription.isCompleted,
          entryDate: convertToDate(prescription.entryDate),
          items: prescription.drugs.map((drug) => {
            return { id: String(drug.id), title: String(drug.drugNameEng) };
          }),
        };
      });
    }, [sourcePrescriptions.data]);

    const isLoading =
      sourcePrescriptions.isLoading || resultPatient.isLoading || resultGenerate.isLoading;

    const controlDrugs = useController({ control, name: 'drugs' });

    const onSubmit = useCallback(
      async (input: FormModel) => {
        if (!resultPatient.data) return;

        const { drugs } = input;
        const res = await generateContent({
          languageID,
          payload: {
            drugs: drugs.map(({ title }) => ({ drugName: String(title) })),
            patient: {
              nameEng: input.nameEng,
              passportNumber: input.passportNumber,
              idNumber: resultPatient.data.idNumber,
            },
          },
        });

        if (isMutationFulfilled(res)) {
          onApply({ content: res.data.content, title: res.data.title, formData: input });
        }
      },
      [resultPatient.data, generateContent, languageID, onApply],
    );

    return (
      <Dialog open={true} fullWidth maxWidth={'lg'} onClose={onClose}>
        <DialogHeading isLoading={isLoading} title={tp('patient-drugs')} onClose={onClose} />
        <DialogContent style={{ paddingBottom: '0' }}>
          <Grid container spacing={2}>
            <Grid md={7} item>
              <AppPrescriptionDrugsAreaInput
                {...getFieldProps(controlDrugs.field)}
                label={t('prescriptions')}
                options={prescriptionOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid md={5} item>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name={'nameEng'}
                    control={control}
                    render={(renderProps) => {
                      return <AppInput {...getFieldProps(renderProps)} disabled={isLoading} />;
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={'passportNumber'}
                    control={control}
                    render={(renderProps) => {
                      return (
                        <AppPassportNumberInput
                          {...getFieldProps(renderProps)}
                          disabled={isLoading}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppChipAreaInput {...getFieldProps(controlDrugs.field)} disabled={isLoading} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {tp('apply')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export const DialogCreateDocTravelLetter = memo<BaseCreateComponentProps>(
  ({ userPatientProfileID, onClose, onDone }) => {
    const [state, setState] = useState<ApplyState>();
    const { tp } = useTranslate();

    const resultPatient = useFetchPatientQuery(userPatientProfileID);
    useEffectError(resultPatient.error);

    const [patch, patchResult] = usePatchMutation();
    useEffectError(patchResult.error);

    const [generateDoc, resultGenerateDoc] = useGenerateDocMutation();
    useEffectError(resultGenerateDoc.error);

    const isLoading =
      resultPatient.isLoading || patchResult.isLoading || resultGenerateDoc.isLoading;

    const onGenerate = useCallback(
      async (content: string) => {
        if (!state) return;
        if (!resultPatient.data) return;

        await generateDoc({ userPatientProfileID, content, title: state.title });

        const formData = {
          appIdentityUserID: userPatientProfileID,
          nameEng: state.formData.nameEng,
          passportNumber: state.formData.passportNumber,
        };

        patch({ initData: resultPatient.data, formData, remark: tp('updates-by-employee') });

        onDone();
      },
      [state, resultPatient.data, generateDoc, onDone, patch, tp, userPatientProfileID],
    );

    if (!state) {
      return (
        <DialogCreateContent
          userPatientProfileID={userPatientProfileID}
          onClose={onClose}
          onApply={setState}
        />
      );
    }

    return (
      <DialogEditHtmlContent
        isLoading={isLoading}
        title={state.title}
        content={state.content}
        onCancel={onClose}
        onGenerate={onGenerate}
      />
    );
  },
);
