import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { addDays } from 'date-fns';
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSourceDocumentTemplates } from 'components/hooks';
import {
  apiPatientFormDocuments,
  iUserPatientProfileFormDocument,
} from 'services/user-patient-profile-form-documents';
import { useEffectError, useOpenValue } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { isMutationFulfilled } from 'utils/rtk-query';
import { DialogValidityContainer } from './components';
import { useOpen } from 'AurionCR/components/hooks';
import { NativeScroll } from '../native-scroll';
import { AppInput } from '../app-input';
import { getSearchFilter } from 'utils/front-filters';
import { ArrayType } from 'utils/types';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import style from './index.module.scss';
import { apiUserPatientProfileSubscriptions } from 'services/user-patient-profile-subscriptions';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useAppDispatch } from 'store';

const usePostDocumentMutation = apiPatientFormDocuments.usePostUserPatientFormDocumentMutation;
const useLazyGetCurrentSubscriptionQuery =
  apiUserPatientProfileSubscriptions.useLazyGetCurrentPatientSubscriptionQuery;
const useGetCurrentSubscriptionQuery =
  apiUserPatientProfileSubscriptions.useGetCurrentPatientSubscriptionQuery;

type Option = {
  id: string;
  title: string | null | undefined;
  isError: boolean;
};

interface Props {
  userPatientProfileID: string;
  formDocumentCategoryID?: string | null;
  postData?: Partial<iUserPatientProfileFormDocument>;
  onDone?: () => void;
  ButtonProp?: Partial<ButtonProps>;
}

export const AddNewDocument = memo<Props>(
  ({ userPatientProfileID, formDocumentCategoryID, onDone, postData, ButtonProp }) => {
    const { t, tp } = useTranslate();
    const dispatch = useAppDispatch();

    const { data: subscription } = useGetCurrentSubscriptionQuery(userPatientProfileID);

    const { isOpen: isOpenList, handleOpen: onOpenList, handleClose: _onCloseList } = useOpen();
    const [search, setSearch] = useState('');

    const onCloseList = useCallback(() => {
      _onCloseList();
      setSearch('');
    }, [_onCloseList]);

    const {
      openValue: formDocumentID,
      onOpen: onOpenTemplate,
      onClose: onCloseTemplate,
    } = useOpenValue<string>();

    const { data, loading, map: mapTemplates } = useSourceDocumentTemplates();
    const doc = formDocumentID ? mapTemplates[formDocumentID] : null;

    const [performPost, resultPost] = usePostDocumentMutation();
    useEffectError(resultPost.error);

    const [performGetSubscription] = useLazyGetCurrentSubscriptionQuery();

    const onSubmit = useCallback(async () => {
      onCloseTemplate();

      if (!doc) return;

      const payload: Partial<iUserPatientProfileFormDocument> = {
        userPatientProfileID,
        expiredDate: addDays(new Date(), 7).toISOString(),
        formDocumentID: doc.id,
        ...postData,
      };

      if (doc.useForContract) {
        const { data: currentSubscription } = await performGetSubscription(userPatientProfileID);

        if (!currentSubscription)
          return dispatch(notifyRequestResult(tp('error-subscription-is-not-defined'), 'error'));

        payload.userPatientProfileSubscriptionID = currentSubscription.id;
        payload.documentExpiredDate = currentSubscription.endDate;
      }

      const result = await performPost(payload);

      if (isMutationFulfilled(result)) {
        onDone && onDone();
      }
    }, [
      userPatientProfileID,
      doc,
      postData,

      onDone,
      onCloseTemplate,

      performPost,
      performGetSubscription,
      tp,
      dispatch,
    ]);

    const factoryClickTemplate = useCallback(
      (id: string) => {
        return () => {
          onOpenTemplate(id);
          onCloseList();
        };
      },
      [onOpenTemplate, onCloseList],
    );

    const items = useMemo(() => {
      let options = formDocumentCategoryID
        ? data.filter((template) => template.formDocumentCategoryID === formDocumentCategoryID)
        : data;

      return options.map((option) => {
        const isError = option.useForContract && !subscription;
        return {
          id: String(option.id),
          title: option.title,
          isError: Boolean(isError),
        };
      });
    }, [data, formDocumentCategoryID, subscription]);

    const isLoading = loading || resultPost.isLoading;

    const buttonRef = useRef(null);

    const filteredList = useMemo(() => {
      const filterCb = getSearchFilter<ArrayType<typeof items>>({
        value: search,
        fields: ['title'],
      });
      return items.filter(filterCb);
    }, [items, search]);

    const renderOption = useCallback(
      ({ id, isError, title }: Option) => {
        return (
          <ListItem
            button
            key={id}
            onClick={factoryClickTemplate(id)}
            className={clsx(style.item, isError && style.itemError)}
            title={isError ? t('subscription-required') : undefined}
          >
            {title}
          </ListItem>
        );
      },
      [t, factoryClickTemplate],
    );

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          startIcon={isLoading ? <CircularProgress size={16} /> : <AddIcon />}
          {...ButtonProp}
          ref={buttonRef}
          onClick={onOpenList}
          disabled={isLoading || items.length === 0}
        >
          {t('add-new')}
        </Button>
        <Popover
          anchorEl={buttonRef.current}
          open={isOpenList}
          onClose={onCloseList}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box p={1} width={300}>
            <AppInput
              placeholder={t('search')}
              value={search}
              autoFocus
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: <SearchIcon color={'primary'} />,
              }}
              variant={'outlined'}
              size={'small'}
            />
            <NativeScroll mode={'visible'} style={{ maxHeight: '30rem' }}>
              <List disablePadding>{filteredList.map(renderOption)}</List>
            </NativeScroll>
          </Box>
        </Popover>
        {doc && (
          <DialogValidityContainer
            formDocumentID={String(doc.id)}
            title={String(doc.title)}
            userPatientProfileID={userPatientProfileID}
            onSubmit={onSubmit}
            onClose={onCloseTemplate}
          />
        )}
      </>
    );
  },
);
