import { DynamicParams, DynamicResult, DynamicService } from 'utils/service';

import {
  API_USER_PATIENT_PROFILE_SESSIONS,
  UserPatientProfileSession,
  UserSessionsGetActivitiesItem,
  UserSessionsGetActivitiesInput,
  SessionForNotebookGenerator,
  PostOrPatchNotebookGeneratorInput,
  PostOrPatchNotebookGeneratorResult,
  CloneClinicalDrugsFromPreviousNotebookInput,
  CloneClinicalDrugsFromPreviousNotebookItem,
  SessionForNotebookGeneratorPreview,
  LatestSessionWithItems,
  SessionsPatientDeclaration,
  SessionWithItems,
} from './models';
import { makeFilterDateRange } from 'utils/app-helpers';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { ServiceUserPatientProfileSessionItems } from 'services/user-patient-profile-session-item';
import { ServiceUserPatientProfileSessionsIcd10s } from 'services/user-patient-profile-session-icd10s';
import { ServiceUserPatientProfileSessionsSurgeries } from 'services/user-patient-profile-session-surgeries';
import { ServiceUserPatientProfileSessionsSensitivities } from 'services/user-patient-profile-session-sensitivities';
import { ServiceUserPatientProfileSessionsRoutineDrugs } from 'services/user-patient-profile-session-routine-drugs';
import { ServiceUserPatientProfileSessionsPastDrugForObesities } from 'services/user-patient-profile-session-past-drug-for-obesities';
import * as dynamic from 'utils/dynamic-helpers';
import { ServiceUserPatientProfileSessionClinicDrugs } from 'services/user-patient-profile-session-clinic-drugs';
import { ServiceUserPatientProfileSessionSideEffects } from 'services/user-patient-profile-session-side-effects';
import { ServiceUserPatientProfileSessionsSensitivityDrugs } from 'services/user-patient-profile-session-sensitivity-drugs';
import { NOTEBOOK_INDEXES } from '../notebook';

export * from './models';

class Service extends DynamicService<UserPatientProfileSession> {
  getDynamicLatest = async <
    Model = UserPatientProfileSession,
    P extends DynamicParams = DynamicParams,
  >(
    params?: P,
  ) => {
    const result = await this.getAllDynamic<Model>({
      ...params,
      orderBy: 'entryDate desc',
      take: 1,
    });

    return { ...result, data: result.data.value[0] || null };
  };
  cloneClinicalDrugsFromPreviousNotebook = async (
    input: CloneClinicalDrugsFromPreviousNotebookInput,
  ) => {
    const result = await this.getDynamicLatest<CloneClinicalDrugsFromPreviousNotebookItem>({
      filter: dynamic
        .mergeFilters(
          `id!="${input.userPatientProfileSessionID}"`,
          `userPatientProfileID=="${input.userPatientProfileID}"`,
          'userPatientProfileSessionClinicDrugs.Count() > 0',
          'notebook.isForPrescriptionOnly==false',
        )
        .join('&&'),
      select: [
        'id',
        'userPatientProfileSessionClinicDrugs.Where(d=>d.isActive).Select(d=> new { d.drugID, d.dosageForm }) as userPatientProfileSessionClinicDrugs',
      ].join(','),
    });

    const latestSession = result.data;

    if (!latestSession) {
      return 'there-is-no-latest-session';
    }

    await Promise.all(
      latestSession.userPatientProfileSessionClinicDrugs.map((clinicDrug) => {
        return ServiceUserPatientProfileSessionClinicDrugs.post({
          userPatientProfileSessionID: input.userPatientProfileSessionID,
          drugID: clinicDrug.drugID,
          dosageForm: clinicDrug.dosageForm,
        });
      }),
    );
  };
  postOrPatchNotebookGenerator = async (input: PostOrPatchNotebookGeneratorInput) => {
    const {
      userPatientProfileSessionID,
      userPatientProfileID,
      userPatientProfileSessionItems,

      icd10IDs,
      surgeryIDs,
      sensitivityIDs,
      regularDrugIDs,
      pastDrugIDs,
      sideEffectIDs,
      sensitivityDrugIDs,

      ...rest
    } = input;

    let id = userPatientProfileSessionID;

    if (!id) {
      const resultCreate = await this.post({
        userPatientProfileID,
        ...rest,
      });

      id = resultCreate.data.id;
    } else {
      await this.patch({ id, ...rest });
    }

    if (!id) {
      throw new Error('userPatientProfileSessionID is not defined');
    }

    const bulkList = [
      icd10IDs &&
        ServiceUserPatientProfileSessionsIcd10s.createBulk({
          userPatientProfileSessionID: id,
          icd10IDs,
        }),
      sideEffectIDs &&
        ServiceUserPatientProfileSessionSideEffects.createBulk({
          userPatientProfileSessionID: id,
          sideEffectIDs,
        }),
      surgeryIDs &&
        ServiceUserPatientProfileSessionsSurgeries.createBulk({
          userPatientProfileSessionID: id,
          surgeryIDs,
        }),
      sensitivityIDs &&
        ServiceUserPatientProfileSessionsSensitivities.createBulk({
          userPatientProfileSessionID: id,
          sensitivityIDs,
        }),
      regularDrugIDs &&
        ServiceUserPatientProfileSessionsRoutineDrugs.createBulk({
          userPatientProfileSessionID: id,
          routineDrugIDs: regularDrugIDs,
        }),
      pastDrugIDs &&
        ServiceUserPatientProfileSessionsPastDrugForObesities.createBulk({
          userPatientProfileSessionID: id,
          pastDrugIDs,
        }),
      sensitivityDrugIDs &&
        ServiceUserPatientProfileSessionsSensitivityDrugs.createBulk({
          userPatientProfileSessionID: id,
          sensitivityDrugIDs,
        }),
    ].filter(Boolean);

    const items = userPatientProfileSessionItems.map((item, i) => {
      const { formValue, ...rest } = item;
      return {
        ...rest,
        rank: i + 1,
        entryValueString: ServiceUserPatientProfileSessionItems.clearEntryValueString(
          item.entryValueString,
        ),
        internalSystemValue:
          ServiceUserPatientProfileSessionItems.makeInternalSystemValue(formValue),
        userPatientProfileSessionID: id,
      };
    });

    const itemsToSave = items.filter(({ entryValueString }) => entryValueString);

    const itemsResult = await Promise.all(
      itemsToSave.map((item) => {
        return ServiceUserPatientProfileSessionItems.postOrPatch(item);
      }),
    );

    const itemsToDelete = items.filter(({ entryValueString, id }) => entryValueString === '' && id);

    await Promise.all(
      itemsToDelete.map((item) => {
        return ServiceUserPatientProfileSessionItems.delete(item);
      }),
    );

    await Promise.all(bulkList);

    return {
      data: {
        userPatientProfileSessionID: id,
        userPatientProfileSessionItems: itemsResult.map((resSessionItem) => resSessionItem.data),
      },
    };
  };
  getActivities = async (input: UserSessionsGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;
    const {
      data: { value },
    } = await this.getAllDynamic<UserSessionsGetActivitiesItem>({
      select: [
        'id',
        'entryDate',
        'notebook.labelKey as title',
        'userEmployeeProfile.fullName as employeeFullName',
      ].join(','),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        'notebook.isForPrescriptionOnly==false',
        makeFilterDateRange('entryDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'entryDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.title,
      date: item.entryDate,
      employee: item.employeeFullName,
      download: null,
    }));
  };

  getAllDynamicUnlimited = <
    Model = UserPatientProfileSession,
    Params extends DynamicParams = DynamicParams,
  >(
    params?: Params,
  ) => {
    return this.engine.post<DynamicResult<Model, Params>>(
      API_USER_PATIENT_PROFILE_SESSIONS.GET_ALL_DYNAMIC_POST,
      params,
    );
  };

  // private async clearData() {
  //   let condition = true;
  //   const TAKE = 100;
  //
  //   let skip = 0;
  //   let removed = 0;
  //
  //   while (condition) {
  //     try {
  //       const {
  //         data: { value, count },
  //       } = await this.getAllDynamic({
  //         select: 'id,entryDate',
  //         take: TAKE,
  //         skip,
  //       });
  //
  //       const items = value.filter((item) => convertToDate(item.entryDate).getFullYear() < 2022);
  //
  //       // perform delete
  //
  //       let promises = items.map((item) => {
  //         return this.delete(item);
  //       });
  //
  //       removed += items.length;
  //
  //       skip += TAKE - items.length;
  //
  //       condition = value.length > 0;
  //
  //       console.log({ removed, count, skip });
  //       await Promise.all(promises);
  //     } catch {
  //       continue;
  //     }
  //   }
  // }
}

export const ServiceUserPatientProfileSessions = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSIONS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSIONS.POST,
  delete: API_USER_PATIENT_PROFILE_SESSIONS.DELETE,
  patch: API_USER_PATIENT_PROFILE_SESSIONS.PATCH,
});

export const apiUserPatientProfileSessions = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getSessionForNotebookGenerator: builder.query({
      queryFn: async (userPatientProfileSessionID: string) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getDynamic<SessionForNotebookGenerator>(
              userPatientProfileSessionID,
              {
                select: dynamic.select(
                  'id',
                  'createdDate',
                  'icd10Remarks',
                  'sideEffectRemarks',
                  'surgeryRemarks',
                  'sensitivityRemarks',
                  'sensitivityDrugRemarks',
                  'routineDrugRemarks',
                  'pastDrugForObesityRemarks',
                  'userPatientProfileSessionItems.Select(i=> new { i.id, i.externalSystemValue, i.internalSystemValue, i.fieldInputID }) as userPatientProfileSessionItems',
                  'userPatientProfileSessionIcd10s.Select(k=>icd10ID) as icd10IDs',
                  'userPatientProfileSessionSideEffects.Select(k=>icd10ID) as sideEffectIDs',
                  'userPatientProfileSessionSurgeries.Select(k=>icd10ID) as surgeryIDs',
                  'userPatientProfileSessionSensitivities.Select(k=>icd10ID) as sensitivityIDs',
                  'userPatientProfileSessionSensitivityDrugs.Select(k=>drugID) as sensitivityDrugIDs',
                  'userPatientProfileSessionRoutineDrugs.Select(k=>drugID) as regularDrugIDs',
                  'userPatientProfileSessionPastDrugForObesities.Select(k=>drugID) as pastDrugIDs',
                ),
              },
            );
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.SESSIONS, id: arg }],
    }),

    getAllSessionWithIcd10s: builder.query({
      queryFn: async (input: {
        userPatientProfileSessionID: string | null;
        userPatientProfileID: string;
      }) => {
        try {
          const result = await ServiceUserPatientProfileSessions.getAllDynamic<SessionWithItems>({
            select: dynamic.select(
              'id',
              'createdDate',
              'icd10Remarks as remarks',
              'new { notebook.labelKey } as notebook',
              'new { userEmployeeProfile.fullName } as userEmployeeProfile',
              'userPatientProfileSessionIcd10s.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title, k.isActive, k.updatedDate }) as previewItems',
            ),
            filter: dynamic
              .mergeFilters(
                'userPatientProfileSessionIcd10s.count() > 0',
                `userPatientProfileID=="${input.userPatientProfileID}"`,
                input.userPatientProfileSessionID && `id!="${input.userPatientProfileSessionID}"`,
              )
              .join('&&'),
            orderBy: 'entryDate desc',
          });
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}__icd10` },
      ],
    }),
    activateOrDeactivateSessionIcd10: builder.mutation<
      void,
      {
        id: string;
        userPatientProfileID: string;
        isActive: boolean;
      }
    >({
      queryFn: async (input) => {
        try {
          await ServiceUserPatientProfileSessionsIcd10s.patch({
            id: input.id,
            isActive: input.isActive,
            updatedDate: new Date().toISOString(),
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}__icd10` },
      ],
    }),
    getAllSessionWithSideEffects: builder.query({
      queryFn: async (input: {
        userPatientProfileSessionID: string | null;
        userPatientProfileID: string;
      }) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getAllDynamic<LatestSessionWithItems>({
              select: dynamic.select(
                'id',
                'createdDate',
                'sideEffectRemarks as remarks',
                'new { notebook.labelKey } as notebook',
                'new { userEmployeeProfile.fullName } as userEmployeeProfile',
                'userPatientProfileSessionSideEffects.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as previewItems',
              ),
              filter: dynamic
                .mergeFilters(
                  'userPatientProfileSessionSideEffects.count() > 0',
                  `userPatientProfileID=="${input.userPatientProfileID}"`,
                  input.userPatientProfileSessionID && `id!="${input.userPatientProfileSessionID}"`,
                )
                .join('&&'),
              orderBy: 'entryDate desc',
            });
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),
    getAllSessionWithSurgeries: builder.query({
      queryFn: async (input: {
        userPatientProfileSessionID: string | null;
        userPatientProfileID: string;
      }) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getAllDynamic<LatestSessionWithItems>({
              select: dynamic.select(
                'id',
                'createdDate',
                'surgeryRemarks as remarks',
                'new { notebook.labelKey } as notebook',
                'new { userEmployeeProfile.fullName } as userEmployeeProfile',
                'userPatientProfileSessionSurgeries.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as previewItems',
              ),
              filter: dynamic
                .mergeFilters(
                  'userPatientProfileSessionSurgeries.count() > 0',
                  `userPatientProfileID=="${input.userPatientProfileID}"`,
                  input.userPatientProfileSessionID && `id!="${input.userPatientProfileSessionID}"`,
                )
                .join('&&'),
            });
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),
    getAllSessionWithSensitivities: builder.query({
      queryFn: async (input: {
        userPatientProfileSessionID: string | null;
        userPatientProfileID: string;
      }) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getAllDynamic<LatestSessionWithItems>({
              select: [
                'id',
                'createdDate',
                'sensitivityRemarks as remarks',
                'new { notebook.labelKey } as notebook',
                'new { userEmployeeProfile.fullName } as userEmployeeProfile',
                'userPatientProfileSessionSensitivities.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as previewItems',
              ].join(','),
              filter: dynamic
                .mergeFilters(
                  'userPatientProfileSessionSensitivities.count() > 0',
                  `userPatientProfileID=="${input.userPatientProfileID}"`,
                  input.userPatientProfileSessionID && `id!="${input.userPatientProfileSessionID}"`,
                )
                .join('&&'),
            });
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),

    getAllSessionWithRoutineDrugs: builder.query({
      queryFn: async (input: {
        userPatientProfileSessionID: string | null;
        userPatientProfileID: string;
      }) => {
        try {
          const result = await ServiceUserPatientProfileSessions.getAllDynamic<SessionWithItems>({
            select: [
              'id',
              'createdDate',
              'routineDrugRemarks as remarks',
              'new { notebook.labelKey } as notebook',
              'new { userEmployeeProfile.fullName } as userEmployeeProfile',
              'userPatientProfileSessionRoutineDrugs.Select(k=>new { k.id, k.routineDrug.catalogName as title, k.isActive, k.updatedDate }) as previewItems',
            ].join(','),
            filter: dynamic
              .mergeFilters(
                'userPatientProfileSessionRoutineDrugs.count() > 0',
                `userPatientProfileID=="${input.userPatientProfileID}"`,
                input.userPatientProfileSessionID && `id!="${input.userPatientProfileSessionID}"`,
              )
              .join('&&'),
          });
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}__routine-drug` },
      ],
    }),
    activateOrDeactivateSessionRoutineDrug: builder.mutation<
      void,
      {
        id: string;
        userPatientProfileID: string;
        isActive: boolean;
      }
    >({
      queryFn: async (input) => {
        try {
          await ServiceUserPatientProfileSessionsRoutineDrugs.patch({
            id: input.id,
            isActive: input.isActive,
            updatedDate: new Date().toISOString(),
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}__routine-drug` },
      ],
    }),

    getAllSessionWithSensitivityDrugs: builder.query({
      queryFn: async (input: {
        userPatientProfileSessionID: string | null;
        userPatientProfileID: string;
      }) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getAllDynamic<LatestSessionWithItems>({
              select: [
                'id',
                'createdDate',
                'sensitivityDrugRemarks as remarks',
                'new { notebook.labelKey } as notebook',
                'new { userEmployeeProfile.fullName } as userEmployeeProfile',
                'userPatientProfileSessionSensitivityDrugs.Select(k=>new { k.id, k.sensitivityDrug.catalogName as title }) as previewItems',
              ].join(','),
              filter: dynamic
                .mergeFilters(
                  'userPatientProfileSessionSensitivityDrugs.count() > 0',
                  `userPatientProfileID=="${input.userPatientProfileID}"`,
                  input.userPatientProfileSessionID && `id!="${input.userPatientProfileSessionID}"`,
                )
                .join('&&'),
            });
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),
    getAllSessionWithPastDrugs: builder.query({
      queryFn: async (input: {
        userPatientProfileSessionID: string | null;
        userPatientProfileID: string;
      }) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getAllDynamic<LatestSessionWithItems>({
              select: [
                'id',
                'createdDate',
                'pastDrugForObesityRemarks as remarks',
                'new { notebook.labelKey } as notebook',
                'new { userEmployeeProfile.fullName } as userEmployeeProfile',
                'userPatientProfileSessionPastDrugForObesities.Select(k=>new { k.id, k.pastDrugForObesity.catalogName as title }) as previewItems',
              ].join(','),
              filter: dynamic
                .mergeFilters(
                  'userPatientProfileSessionPastDrugForObesities.count() > 0',
                  `userPatientProfileID=="${input.userPatientProfileID}"`,
                  input.userPatientProfileSessionID && `id!="${input.userPatientProfileSessionID}"`,
                )
                .join('&&'),
            });
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),

    getSessionForNotebookGeneratorPreview: builder.query({
      queryFn: async (userPatientProfileSessionID: string) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getDynamic<SessionForNotebookGeneratorPreview>(
              userPatientProfileSessionID,
              {
                select: [
                  'id',
                  'createdDate',
                  'icd10Remarks',
                  'sideEffectRemarks',
                  'surgeryRemarks',
                  'sensitivityRemarks',
                  'sensitivityDrugRemarks',
                  'routineDrugRemarks',
                  'pastDrugForObesityRemarks',
                  'new { notebook.labelKey } as notebook',
                  'new { userEmployeeProfile.fullName } as userEmployeeProfile',
                  'userPatientProfileSessionPastDrugForObesities.Select(k=> k.pastDrugForObesity.catalogName) as pastDrugs',
                  'userPatientProfileSessionItems.Select(i=> new { i.id, i.entryValueString, i.fieldInput.labelKey }) as sessionItems',
                  'userPatientProfileSessionClinicDrugs.Select(i=> new { i.id, i.dosageForm, i.remarks, i.clinicDrug.catalogName }) as clinicalDrugs',
                  'userPatientProfileSessionIcd10s.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as icd10PreviewItems',
                  'userPatientProfileSessionSideEffects.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as sideEffectPreviewItems',
                  'userPatientProfileSessionSurgeries.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as surgeryPreviewItems',
                  'userPatientProfileSessionSensitivities.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as sensitivityPreviewItems',
                  'userPatientProfileSessionRoutineDrugs.Select(k=>new { k.id, k.routineDrug.catalogName as title }) as regularDrugPreviewItems',
                  'userPatientProfileSessionSensitivityDrugs.Select(k=>new { k.id, k.sensitivityDrug.catalogName as title }) as sensitivityDrugPreviewItems',
                  'userPatientProfileSessionPastDrugForObesities.Select(k=>new { k.id, k.pastDrugForObesity.catalogName as title }) as pastDrugPreviewItems',
                ].join(','),
              },
            );
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [
        { type: RTK_TAGS.SESSIONS, id: arg },
        { type: RTK_TAGS.SESSIONS, id: `id__${arg}__clinical_drugs` },
      ],
    }),
    getSessionsFullInformation: builder.query({
      queryFn: async (input: {
        userEmployeeProfileID: string | null;
        userPatientProfileID: string;
        take: number;
        skip: number;
      }) => {
        const { take, skip } = input;
        try {
          const result =
            await ServiceUserPatientProfileSessions.getAllDynamicUnlimited<SessionForNotebookGeneratorPreview>(
              {
                filter: dynamic
                  .mergeFilters(
                    dynamic.createFilterEquals('userPatientProfileID', input.userPatientProfileID),
                    dynamic.createFilterEquals(
                      'userEmployeeProfileID',
                      input.userEmployeeProfileID,
                    ),
                  )
                  .join('&&'),
                select: [
                  'id',
                  'createdDate',
                  'icd10Remarks',
                  'sideEffectRemarks',
                  'surgeryRemarks',
                  'sensitivityRemarks',
                  'sensitivityDrugRemarks',
                  'routineDrugRemarks',
                  'pastDrugForObesityRemarks',
                  'new { notebook.labelKey, notebook.isForPrescriptionOnly } as notebook',
                  'new { userEmployeeProfile.fullName } as userEmployeeProfile',
                  'userPatientProfileSessionPastDrugForObesities.Select(k=> k.pastDrugForObesity.catalogName) as pastDrugs',
                  'userPatientProfileSessionItems.Select(i=> new { i.id, i.entryValueString, i.fieldInput.labelKey }) as sessionItems',
                  'userPatientProfileSessionClinicDrugs.Select(i=> new { i.id, i.dosageForm, i.remarks, i.clinicDrug.catalogName }) as clinicalDrugs',
                  'userPatientProfileSessionIcd10s.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as icd10PreviewItems',
                  'userPatientProfileSessionSideEffects.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as sideEffectPreviewItems',
                  'userPatientProfileSessionSurgeries.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as surgeryPreviewItems',
                  'userPatientProfileSessionSensitivities.Select(k=>new { k.id, (k.icd10.code + " - " + k.icd10.titleHeb + " (" + k.icd10.description + ")") as title }) as sensitivityPreviewItems',
                  'userPatientProfileSessionRoutineDrugs.Select(k=>new { k.id, k.routineDrug.catalogName as title }) as regularDrugPreviewItems',
                  'userPatientProfileSessionSensitivityDrugs.Select(k=>new { k.id, k.sensitivityDrug.catalogName as title }) as sensitivityDrugPreviewItems',
                  'userPatientProfileSessionPastDrugForObesities.Select(k=>new { k.id, k.pastDrugForObesity.catalogName as title }) as pastDrugPreviewItems',
                ].join(','),
                orderBy: 'entryDate desc',
                take,
                skip,
                count: true,
              },
            );
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),

    getSessionPatientLatestSurvey: builder.query<
      Pick<UserPatientProfileSession, 'id' | 'doctorViewed'> | null,
      string
    >({
      queryFn: async (userPatientProfileID) => {
        try {
          const result = await ServiceUserPatientProfileSessions.getAllDynamic({
            filter: dynamic
              .mergeFilters(
                `userPatientProfileID=="${userPatientProfileID}"`,
                dynamic.createFilterValueArrayEquals('notebook.rowIndex', [
                  NOTEBOOK_INDEXES.QUESTIONNAIRE_EN,
                  NOTEBOOK_INDEXES.QUESTIONNAIRE_HE,
                ]),
              )
              .join('&&'),
            select: ['id', 'doctorViewed'].join(','),
            take: 1,
            orderBy: 'entryDate desc',
          });
          return { data: result.data.value[0] || null };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, input) => [
        { type: RTK_TAGS.SESSIONS, id: `patient-surveys__${input}` },
      ],
    }),
    getSessionsPatientSurvey: builder.query({
      queryFn: async (userPatientProfileSessionID: string) => {
        try {
          const result =
            await ServiceUserPatientProfileSessions.getDynamic<SessionsPatientDeclaration>(
              userPatientProfileSessionID,
              {
                select: dynamic.select(
                  'id',
                  'doctorViewed',
                  'entryDate',
                  'userPatientProfileSessionItems.OrderBy(i => i.rank).Select(i=> new { i.id, i.entryValueString, i.fieldInput.labelKey, i.rank, i.fieldInput.inputType.inputTypeParameter.isText as isUrgent }) as sessionItems',
                  `(userPatientProfileSessionPatientDetail != null ? new { 
                        userPatientProfileSessionPatientDetail.lastName,
                        userPatientProfileSessionPatientDetail.firstName,
                        userPatientProfileSessionPatientDetail.idNumber,
                        userPatientProfileSessionPatientDetail.dateOfBirth,
                        userPatientProfileSessionPatientDetail.height,
                        userPatientProfileSessionPatientDetail.weight,
                        userPatientProfileSessionPatientDetail.email,
                        userPatientProfileSessionPatientDetail.mobilePhone,
                        userPatientProfileSessionPatientDetail.address,
                        userPatientProfileSessionPatientDetail.occupation,
                        new { userPatientProfileSessionPatientDetail.city.title } as city
                   } : null) as patientInfo`,
                ),
              },
            );
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, input) => [{ type: RTK_TAGS.SESSIONS, id: `survey__${input}` }],
    }),
    setSessionsPatientSurveyAsViewed: builder.mutation<
      void,
      {
        userPatientProfileSessionID: string;
        userPatientProfileID: string;
      }
    >({
      queryFn: async (input) => {
        try {
          await ServiceUserPatientProfileSessions.patch({
            id: input.userPatientProfileSessionID,
            doctorViewed: true,
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, input) => [
        { type: RTK_TAGS.SESSIONS, id: `survey__${input.userPatientProfileSessionID}` },
        { type: RTK_TAGS.SESSIONS, id: `patient-surveys__${input.userPatientProfileID}` },
      ],
    }),

    postOrPatchNotebookGenerator: builder.mutation<
      PostOrPatchNotebookGeneratorResult,
      PostOrPatchNotebookGeneratorInput
    >({
      queryFn: async (input) => {
        try {
          const result = await ServiceUserPatientProfileSessions.postOrPatchNotebookGenerator(
            input,
          );
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, arg) => [
        { type: RTK_TAGS.SESSIONS },
        { type: RTK_TAGS.SESSIONS, id: res?.userPatientProfileSessionID },
        { type: RTK_TAGS.SESSIONS, id: `patient__${arg.userPatientProfileID}` },
      ],
    }),
    cloneClinicalDrugsFromPreviousNotebook: builder.mutation<
      void,
      CloneClinicalDrugsFromPreviousNotebookInput
    >({
      queryFn: async (input) => {
        try {
          await ServiceUserPatientProfileSessions.cloneClinicalDrugsFromPreviousNotebook(input);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, arg) => [
        { type: RTK_TAGS.SESSIONS, id: `id__${arg.userPatientProfileSessionID}__clinical_drugs` },
      ],
    }),
  }),
});
