import {
  TravelLetterInput,
  TravelLetterPayload,
  LetterToMedInsuranceInput,
  LetterToMedInsurancePayload,
  EndOfTreatmentConsultationPayload,
  EndOfTreatmentConsultationInput,
  DietitianReportInput,
  DietitianReportPayload,
  EndOfTreatmentDetailsInput,
  EndOfTreatmentDetailsPayload,
  EndOfTreatmentInput,
  EndOfTreatmentPayload,
  EmptyLetterInput,
  EmptyLetterPayload,
  HmoLetterInput,
  HmoLetterPayload,
} from './models';
import { apiUserEmployeeProfiles } from 'services/user-employee-profiles';
import { convertToDate, dateFormat, getAge, withFullName } from 'utils/dates';
import { calcHtmlTemplate } from 'utils';
import { apiSiteParams } from 'services/site-parameters';
import { apiDocumentTemplates, CONTENT_KEYS } from 'services/document-templates';
import { APP_FORMAT_DATE } from 'configs/const';
import { i18nAppTranslator } from 'modules/i18n';
import { apiRtk } from 'utils/rtk-query';
import { selectAuthUser } from 'store/auth';
import { RootState } from 'store';
import { calcBmi } from 'utils/app-helpers';
import { compareAsc } from 'date-fns';
import { PERMISSION_IDS } from '../user-employee-profile-permissions';

export * from './models';

class Service {
  generateLetterToMedInsurance(htmlTemplate: string, payload: LetterToMedInsurancePayload) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeLetterToMedInsurance(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeLetterToMedInsurance(payload: LetterToMedInsurancePayload) {
    const mapDrugs = new Map(payload.drugs.map((drug) => [drug.drugName, drug]));

    const drugs = Array.from(mapDrugs.values()).map((drug) => drug.drugName);
    return {
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      patient: {
        ...payload.patient,
        hmo: { title: payload.patient?.hmo?.title || '' },
        diagnosisType: { title: payload.patient?.diagnosisType?.title || '' },
        bmiSummary: { title: payload.patient?.bmiSummary?.title || '' },
        bmi: calcBmi(payload.patient.weight || 1, payload.patient.height || 1),
        age: getAge(payload.patient.dateOfBirth),
      },
      drugs: drugs,
      subscription: {
        ...payload.subscription,
        duration: payload.subscription.durationMonths,
        startDate: dateFormat(payload.startDate, APP_FORMAT_DATE),
      },
      doctor: payload.doctor,
      siteParams: payload.siteParams,
    };
  }

  generateTravelLetter(htmlTemplate: string, payload: TravelLetterPayload) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeTravelLetter(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeTravelLetter(payload: TravelLetterPayload) {
    return {
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      patient: payload.patient,
      siteParams: payload.siteParams,
      drugs: Array.from(new Set(payload.drugs.map(({ drugName }) => drugName)).keys()).join(', '),
      doctor: payload.doctor,
    };
  }

  generateEmptyLetter(htmlTemplate: string, payload: EmptyLetterPayload) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeEmptyLetter(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeEmptyLetter(payload: EmptyLetterPayload) {
    return {
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      patient: { ...payload.patient, age: getAge(payload.patient.dateOfBirth) },
      siteParams: payload.siteParams,
      doctor: payload.doctor,
    };
  }

  generateEndTreatmentConsultation(
    htmlTemplate: string,
    payload: EndOfTreatmentConsultationPayload,
  ) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeEndTreatmentConsultation(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeEndTreatmentConsultation(payload: EndOfTreatmentConsultationPayload) {
    return {
      date: dateFormat(payload.date, APP_FORMAT_DATE),
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      patient: {
        ...payload.patient,
        hmo: { title: payload.patient?.hmo?.title || '' },
        diagnosisType: { title: payload.patient?.diagnosisType?.title || '' },
        bmiSummary: { title: payload.patient?.bmiSummary?.title || '' },
        bmi: calcBmi(payload.patient.weight || 1, payload.patient.height || 1),
        age: getAge(payload.patient.dateOfBirth),
      },
      doctor: payload.doctor,
      siteParams: payload.siteParams,
      subscription: { price: payload.price === null ? null : String(payload.price) },
    };
  }

  generateDietitianReport(htmlTemplate: string, payload: DietitianReportPayload) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeDietitianReport(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeDietitianReport(payload: DietitianReportPayload) {
    const weights = [...payload.weights]
      .sort((a, b) => compareAsc(convertToDate(a.date), convertToDate(b.date)))
      .map((item, index) =>
        i18nAppTranslator.tp('dietitian-report-weight-item', {
          weight: item.weight,
          date: dateFormat(item.date),
          index: index + 1,
          name: item.name,
        }),
      );

    return {
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      patient: withFullName({
        ...payload.patient,
        hmo: { title: payload.patient?.hmo?.title || '' },
        age: getAge(payload.patient.dateOfBirth),
      }),
      dietitian: withFullName(payload.dietitian),
      weights,
      siteParams: payload.siteParams,
    };
  }

  generateEndOfTreatmentDetails(htmlTemplate: string, payload: EndOfTreatmentDetailsPayload) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeEndOfTreatmentDetails(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeEndOfTreatmentDetails(payload: EndOfTreatmentDetailsPayload) {
    const mapDrugs = new Map(payload.drugs.map((drug) => [drug.drugName, drug]));

    // remove duplicates
    const drugs = Array.from(mapDrugs.values()).map((drug) => drug.drugName);

    const allMeetings = payload.meetings.sort((a, b) => {
      return compareAsc(convertToDate(a.meetingToDateTime), convertToDate(b.meetingToDateTime));
    });

    const mapMeetings = new Map<string, typeof allMeetings>();

    allMeetings.forEach((meeting) => {
      const date = dateFormat(meeting.meetingFromDateTime, APP_FORMAT_DATE);

      const oldItems = mapMeetings.get(date) || [];

      mapMeetings.set(date, [...oldItems, meeting]);
    });

    const meetings = Array.from(mapMeetings.entries()).map(([date, meetings]) => {
      const withDoctor = meetings.some(
        (meeting) =>
          meeting.userEmployeeProfile?.userEmployeeProfilePermissionID === PERMISSION_IDS.DOCTOR,
      );
      const withDietitian = meetings.some(
        (meeting) =>
          meeting.userEmployeeProfile?.userEmployeeProfilePermissionID === PERMISSION_IDS.DIETITIAN,
      );
      const title = [
        withDoctor && i18nAppTranslator.tp('doctor'),
        withDietitian && i18nAppTranslator.tp('dietitian'),
      ]
        .filter(Boolean)
        .join(' + ');

      return [date, title].join(' - ');
    });

    return {
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      patient: {
        ...payload.patient,
        hmo: { title: payload.patient?.hmo?.title || '' },
        diagnosisType: { title: payload.patient?.diagnosisType?.title || '' },
        bmiSummary: { title: payload.patient?.bmiSummary?.title || '' },
        bmi: calcBmi(payload.patient.weight || 1, payload.patient.height || 1),
        age: getAge(payload.patient.dateOfBirth),
      },
      drugs: drugs,
      meetings,
      subscription: {
        ...payload.subscription,
        duration: payload.subscription.durationMonths,
        startDate: dateFormat(payload.subscription.startDate),
      },
      doctor: payload.doctor,
      siteParams: payload.siteParams,
    };
  }

  generateEndOfTreatment(htmlTemplate: string, payload: EndOfTreatmentPayload) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeEndOfTreatment(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeEndOfTreatment(payload: EndOfTreatmentPayload) {
    return {
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      period: payload.period,
      prescribedMedications: payload.prescribedMedications.split(',').join(', '),
      startingWeight: payload.startingWeight,
      startingBMI: payload.startingBMI,
      currentWeight: payload.currentWeight,
      currentBMI: payload.currentBMI,
      doctorRecommendation: payload.doctorRecommendation,
      patient: payload.patient,
      doctor: {
        signature: payload.doctor.signature,
      },
      siteParams: {
        logo: payload.siteParams.logo,
      },
    };
  }

  generateHmoLetter(htmlTemplate: string, payload: HmoLetterPayload) {
    return calcHtmlTemplate(htmlTemplate, {
      ...Service.normalizeHmoLetter(payload),
      translate: i18nAppTranslator.t,
    });
  }
  static normalizeHmoLetter(payload: HmoLetterPayload) {
    return {
      today: dateFormat(new Date(), APP_FORMAT_DATE),
      doctorRecommendation: payload.doctorRecommendation,
      patient: {
        ...payload.patient,
        age: getAge(payload.patient.dateOfBirth),
        bmi: calcBmi(payload.patient.weight || 0, payload.patient.height || 0),
      },
      subscription: {
        duration: payload.subscription.durationMonths,
        startDate: dateFormat(payload.subscription.startDate),
        visitDietitian: payload.subscription.visitDietitian,
      },
      doctor: {
        signature: payload.doctor.signature,
      },
      siteParams: {
        logo: payload.siteParams.logo,
      },
    };
  }
}
type BaseOutput = { content: string; title: string };
const ServicePdfContent = new Service();

export const apiPdfContent = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    generateContentTravelLetter: builder.mutation<BaseOutput, TravelLetterInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const requestContent = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.TRAVEL_LETTER,
            ),
          );
          const requestSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
          );

          const requestDoctor = dispatch(
            apiUserEmployeeProfiles.endpoints.getDoctorDetailsForPdf.initiate(),
          );

          requestContent.unsubscribe();
          requestSiteParams.unsubscribe();
          requestDoctor.unsubscribe();

          const [resultContent, resultSiteParams, resultDoctor] = await Promise.all([
            requestContent.unwrap(),
            requestSiteParams.unwrap(),
            requestDoctor.unwrap(),
          ]);

          const htmlTemplate = resultContent.html ?? '';
          const title = resultContent.title ?? '';

          const content = ServicePdfContent.generateTravelLetter(htmlTemplate, {
            ...input.payload,
            siteParams: resultSiteParams,
            doctor: resultDoctor,
          });

          return { data: { content, title } };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateContentEmptyLetter: builder.mutation<BaseOutput, EmptyLetterInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const requestContent = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.EMPTY_LETTER,
            ),
          );
          const requestSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
          );

          const requestDoctor = dispatch(
            apiUserEmployeeProfiles.endpoints.getDoctorDetailsForPdf.initiate(),
          );

          requestContent.unsubscribe();
          requestSiteParams.unsubscribe();
          requestDoctor.unsubscribe();

          const [resultContent, resultSiteParams, resultDoctor] = await Promise.all([
            requestContent.unwrap(),
            requestSiteParams.unwrap(),
            requestDoctor.unwrap(),
          ]);

          const htmlTemplate = resultContent.html ?? '';
          const title = resultContent.title ?? '';

          const content = ServicePdfContent.generateEmptyLetter(htmlTemplate, {
            ...input.payload,
            siteParams: resultSiteParams,
            doctor: resultDoctor,
          });

          return { data: { content, title } };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateContentLetterToMedInsurance: builder.mutation<BaseOutput, LetterToMedInsuranceInput>({
      queryFn: async (input, { dispatch, getState }) => {
        const employee = selectAuthUser(getState() as RootState);

        if (!employee) {
          throw new Error('Unauthorized');
        }

        const requestContent = dispatch(
          apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
            CONTENT_KEYS.LETTER_TO_MED_INSURANCE,
          ),
        );
        const requestSiteParams = dispatch(
          apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
        );
        const requestDoctor = dispatch(
          apiUserEmployeeProfiles.endpoints.getDoctorDetailsForPdf.initiate(),
        );

        requestContent.unsubscribe();
        requestSiteParams.unsubscribe();
        requestDoctor.unsubscribe();

        const [resultContent, resultSiteParams, resultDoctor] = await Promise.all([
          requestContent.unwrap(),
          requestSiteParams.unwrap(),
          requestDoctor.unwrap(),
        ]);

        const htmlTemplate = resultContent.html ?? '';
        const title = resultContent.title ?? '';

        const content = ServicePdfContent.generateLetterToMedInsurance(htmlTemplate, {
          ...input.payload,
          siteParams: resultSiteParams,
          doctor: resultDoctor,
        });

        return { data: { content, title } };
      },
    }),
    generateContentEndTreatmentConsultation: builder.mutation<
      BaseOutput,
      EndOfTreatmentConsultationInput
    >({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const employee = selectAuthUser(getState() as RootState);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const requestContent = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.SUMMARY_OF_CONSULTATION,
            ),
          );
          const requestSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
          );
          const requestDoctor = dispatch(
            apiUserEmployeeProfiles.endpoints.getDoctorDetailsForPdf.initiate(),
          );

          requestContent.unsubscribe();
          requestSiteParams.unsubscribe();
          requestDoctor.unsubscribe();

          const [resultContent, resultSiteParams, resultDoctor] = await Promise.all([
            requestContent.unwrap(),
            requestSiteParams.unwrap(),
            requestDoctor.unwrap(),
          ]);

          const htmlTemplate = resultContent.html ?? '';
          const title = resultContent.title ?? '';

          const content = ServicePdfContent.generateEndTreatmentConsultation(htmlTemplate, {
            ...input.payload,
            siteParams: resultSiteParams,
            doctor: resultDoctor,
          });

          return { data: { title, content } };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateContentDietitianReport: builder.mutation<BaseOutput, DietitianReportInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const employee = selectAuthUser(getState() as RootState);
          if (!employee) {
            throw new Error('Unauthorized');
          }

          const requestContent = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.DIETITIAN_REPORT,
            ),
          );
          const requestSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
          );

          const [resultContent, resultSiteParams] = await Promise.all([
            requestContent.unwrap(),
            requestSiteParams.unwrap(),
          ]);

          requestContent.unsubscribe();
          requestSiteParams.unsubscribe();

          const htmlTemplate = resultContent.html || '';
          const title = resultContent.title || '';

          const content = ServicePdfContent.generateDietitianReport(htmlTemplate, {
            ...input.payload,
            siteParams: resultSiteParams,
          });

          return { data: { content, title } };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateContentEndTreatmentDetails: builder.mutation<BaseOutput, EndOfTreatmentDetailsInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const requestContent = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.DETAILS_OF_TREATMENT,
            ),
          );
          const requestSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
          );

          const requestDoctor = dispatch(
            apiUserEmployeeProfiles.endpoints.getDoctorDetailsForPdf.initiate(),
          );

          requestDoctor.unsubscribe();
          requestContent.unsubscribe();
          requestSiteParams.unsubscribe();

          const [resultContent, resultSiteParams, resultDoctor] = await Promise.all([
            requestContent.unwrap(),
            requestSiteParams.unwrap(),
            requestDoctor.unwrap(),
          ]);

          const htmlTemplate = resultContent.html ?? '';
          const title = resultContent.title ?? '';

          const content = ServicePdfContent.generateEndOfTreatmentDetails(htmlTemplate, {
            ...input.payload,
            siteParams: resultSiteParams,
            doctor: resultDoctor,
          });

          return { data: { content, title } };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),

    generateContentEndTreatment: builder.mutation<BaseOutput, EndOfTreatmentInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const contentKey = input.contentKey;

          const requestContent = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(contentKey),
          );
          const requestSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
          );

          const requestDoctor = dispatch(
            apiUserEmployeeProfiles.endpoints.getDoctorDetailsForPdf.initiate(),
          );

          requestDoctor.unsubscribe();
          requestContent.unsubscribe();
          requestSiteParams.unsubscribe();

          const [resultContent, resultSiteParams, resultDoctor] = await Promise.all([
            requestContent.unwrap(),
            requestSiteParams.unwrap(),
            requestDoctor.unwrap(),
          ]);

          const htmlTemplate = resultContent.html ?? '';
          const title = resultContent.title ?? '';
          const content = ServicePdfContent.generateEndOfTreatment(htmlTemplate, {
            ...input.payload,
            siteParams: resultSiteParams,
            doctor: resultDoctor,
          });

          return { data: { content, title } };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateContentHmoLetter: builder.mutation<BaseOutput, HmoLetterInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const requestContent = dispatch(
            apiDocumentTemplates.endpoints.getDocumentTemplateByContentKey.initiate(
              CONTENT_KEYS.HMO_LETTER,
            ),
          );
          const requestSiteParams = dispatch(
            apiSiteParams.endpoints.getSiteParamsPdfInfo.initiate(input.languageID),
          );

          const requestDoctor = dispatch(
            apiUserEmployeeProfiles.endpoints.getDoctorDetailsForPdf.initiate(),
          );

          requestDoctor.unsubscribe();
          requestContent.unsubscribe();
          requestSiteParams.unsubscribe();

          const [resultContent, resultSiteParams, resultDoctor] = await Promise.all([
            requestContent.unwrap(),
            requestSiteParams.unwrap(),
            requestDoctor.unwrap(),
          ]);

          const htmlTemplate = resultContent.html ?? '';
          const title = resultContent.title ?? '';
          const content = ServicePdfContent.generateHmoLetter(htmlTemplate, {
            ...input.payload,
            siteParams: resultSiteParams,
            doctor: resultDoctor,
          });

          return { data: { content, title } };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
