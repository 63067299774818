import { API_PDF, Gimel29Payload } from './models';
import { bufferToBlobPdf, fileToBase64, getBase64FileSize } from 'utils/file-uploader';
import { UserEmployeeProfile } from 'services/user-employee-profiles';
import { UserPatientProfile } from 'services/user-patient-profile';
import { dateFormat, getAge } from 'utils/dates';
import { calcHtmlTemplate } from 'utils';
import { SiteParametersMainInfo } from 'services/site-parameters';
import { ServicePatientPrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { CONTENT_KEYS, ServiceDocumentTemplates } from 'services/document-templates';
import { APP_FORMAT_DATE } from 'configs/const';
import { calcBmi } from 'utils/app-helpers';
import { api } from 'utils/service';
import { i18nAppTranslator } from 'modules/i18n';
import { apiRtk } from 'utils/rtk-query';
import {
  apiUserPatientProfileDocuments,
  UserPatientProfileDocument,
} from '../user-patient-profile-documents';
import { selectAuthUser } from 'store/auth';
import { RootState } from 'store';

export * from './models';

type PdfFromStringOptions = {
  content: string;
  displayWatermark?: boolean;
};
interface PdfPrescriptionOptions {
  payload: {
    doctor: Pick<Required<UserEmployeeProfile>, 'fullName' | 'licenseNumber' | 'signature'>;
    patient: Pick<Required<UserPatientProfile>, 'fullName' | 'idNumber' | 'city' | 'mobilePhone'>;
    siteParams: SiteParametersMainInfo;
    entryDate: string;
    drugs: {
      drugName: string | null;
      dosageForm: string | null;
      dosageFormTotal: number | null;
      dosageFormDaily: string | null;
      instructions: string | null;
    }[];
    remarks: string;
  };
}
interface PdfPrescriptionPharmacyOptions {
  htmlTemplate: string;
  userPatientProfilePrescriptionID: string;
  payload: {
    doctor: Pick<Required<UserEmployeeProfile>, 'fullName' | 'licenseNumber' | 'signature'>;
    patient: Pick<Required<UserPatientProfile>, 'fullName' | 'idNumber' | 'city' | 'mobilePhone'>;
    siteParams: SiteParametersMainInfo;
    entryDate: string;
    drugs: {
      drugName: string | null;
      dosageForm: string | null;
      dosageFormTotal: number | null;
      dosageFormDaily: string | null;
      instructions: string | null;
    }[];
    remarks: string;
  };
}

interface PdfGimel29Options {
  htmlTemplate: string;
  payload: Gimel29Payload;
}

class Service {
  async generatePages(data: { pages: PdfFromStringOptions[] }) {
    const { data: pdfBuffer } = await api.post<Buffer>(API_PDF.GENERATE, data, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });

    return { data: await fileToBase64(bufferToBlobPdf(pdfBuffer)) };
  }
  async generatePdf(data: PdfFromStringOptions) {
    return ServicePdf.generatePages({ pages: [data] });
  }
  async generatePdfComplex(data: PdfFromStringOptions) {
    const dataWithLogo = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data.content, 'text/html');

    doc.head.insertAdjacentHTML('beforeend', '<style>.logo{ opacity: 0 }</style>');

    let dataWithoutLogo = { ...data, content: doc.documentElement.innerHTML };

    const [{ data: withLogo }, { data: withoutLogo }] = await Promise.all([
      ServicePdf.generatePages({ pages: [dataWithLogo] }),
      ServicePdf.generatePages({ pages: [dataWithoutLogo] }),
    ]);

    return { withLogo, withoutLogo };
  }

  generatePrescriptionPatient = async function (options: PdfPrescriptionOptions) {
    const [
      {
        data: { html: temp },
      },
      {
        data: { html: tempPrint },
      },
    ] = await Promise.all([
      ServiceDocumentTemplates.getByContentKey(CONTENT_KEYS.PRESCRIPTION),
      ServiceDocumentTemplates.getByContentKey(CONTENT_KEYS.PRESCRIPTION_PRINT),
    ]);

    const { payload } = options;

    const templatePayload = {
      ...Service.normalizePrescription(payload),
      translate: i18nAppTranslator.t,
    };

    const content = calcHtmlTemplate(temp, templatePayload);
    const contentPrint = calcHtmlTemplate(tempPrint, templatePayload);

    const pagesWithoutWatermark = [
      {
        content,
        displayWatermark: false,
      },
    ];
    const pagesWithWatermark = [
      {
        content,
        displayWatermark: true,
      },
    ];
    const pagesPrint = [
      {
        content: contentPrint,
        displayWatermark: false,
      },
    ];

    const [resultWithout, resultWith, resultPrint] = await Promise.all([
      ServicePdf.generatePages({ pages: pagesWithoutWatermark }),
      ServicePdf.generatePages({ pages: pagesWithWatermark }),
      ServicePdf.generatePages({ pages: pagesPrint }),
    ]);

    return {
      withWatermark: resultWith.data,
      withoutWatermark: resultWithout.data,
      forPrint: resultPrint.data,
    };
  };
  generatePrescriptionPharmacy = async function (options: PdfPrescriptionPharmacyOptions) {
    const { htmlTemplate, payload, userPatientProfilePrescriptionID } = options;
    const [
      {
        data: { html: htmlTemplateForm29 },
      },
      detailsList,
    ] = await Promise.all([
      ServiceDocumentTemplates.getByContentKey(CONTENT_KEYS.GIMEL_29),
      ServicePatientPrescriptionDetails.getGimel29Details(userPatientProfilePrescriptionID),
    ]);

    const pages = [
      {
        content: calcHtmlTemplate(htmlTemplate, {
          ...Service.normalizePrescription(payload),
          translate: i18nAppTranslator.t,
        }),
        displayWatermark: false,
      },
      ...detailsList.map((item) => ({
        content: calcHtmlTemplate(htmlTemplateForm29, {
          ...Service.normalizePayloadGimel29(item),
          translate: i18nAppTranslator.t,
        }),
        displayWatermark: false,
      })),
    ];
    return ServicePdf.generatePages({ pages });
  };
  static normalizePrescription(data: PdfPrescriptionOptions['payload']) {
    return {
      ...data,
      entryDate: dateFormat(data.entryDate, APP_FORMAT_DATE),
    };
  }

  generateGimel29 = async function (options: PdfGimel29Options) {
    const { htmlTemplate, payload } = options;

    const normalizedData = Service.normalizePayloadGimel29(payload);

    const content = calcHtmlTemplate(htmlTemplate, {
      ...normalizedData,
      translate: i18nAppTranslator.t,
    });

    return ServicePdf.generatePdf({
      content,
      displayWatermark: true,
    });
  };
  generateGimel29WithoutWatermark = async function (options: PdfGimel29Options) {
    const { htmlTemplate, payload } = options;

    const normalizedData = Service.normalizePayloadGimel29(payload);

    const content = calcHtmlTemplate(htmlTemplate, {
      ...normalizedData,
      translate: i18nAppTranslator.t,
    });

    return ServicePdf.generatePdf({
      content,
      displayWatermark: false,
    });
  };
  static normalizePayloadGimel29(payload: PdfGimel29Options['payload']) {
    return {
      ...payload,
      patient: {
        ...payload.patient,
        age: getAge(payload.patient.dateOfBirth),
        bmi: calcBmi(payload.patient.weight || 0, payload.patient.height || 0),
      },
      date: dateFormat(new Date(), APP_FORMAT_DATE),
    };
  }
}

type BaseInput = { userPatientProfileID: string; content: string; title: string };

export const ServicePdf = new Service();

export const apiPdf = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    generateDocumentEndTreatment: builder.mutation<UserPatientProfileDocument, BaseInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const { content, title } = input;
          const { withLogo, withoutLogo } = await ServicePdf.generatePdfComplex({
            content,
          });

          const fileName = `end_of_treatment__${new Date().toISOString()}.pdf`;

          const requestDoc = dispatch(
            apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate(
              {
                userPatientProfileID: input.userPatientProfileID,
                userEmployeeProfileID: String(employee.appUserID),
                isForm29: false,
                isCopy: false,

                fileName: title,

                documentURL: {
                  value: withLogo,
                  name: fileName,
                  size: getBase64FileSize(withLogo),
                  type: 'application/pdf',
                },
                documentForPrintURL: {
                  value: withoutLogo,
                  name: fileName,
                  size: getBase64FileSize(withoutLogo),
                  type: 'application/pdf',
                },
              },
            ),
          );

          const resultDoc = await requestDoc.unwrap();

          return { data: resultDoc };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),

    generateDocumentDietitianReport: builder.mutation<UserPatientProfileDocument, BaseInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const employee = selectAuthUser(getState() as RootState);
          if (!employee) {
            throw new Error('Unauthorized');
          }

          const { content, title } = input;

          const { withLogo, withoutLogo } = await ServicePdf.generatePdfComplex({
            content,
          });

          const fileName = `dietitian-report__${new Date().toISOString()}.pdf`;

          const requestDoc = dispatch(
            apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate(
              {
                userPatientProfileID: input.userPatientProfileID,
                userEmployeeProfileID: String(employee.appUserID),
                isForm29: false,
                isCopy: false,
                fileName: title || 'unknown-content',
                documentURL: {
                  value: withLogo,
                  name: fileName,
                  size: getBase64FileSize(withLogo),
                  type: 'application/pdf',
                },
                documentForPrintURL: {
                  value: withoutLogo,
                  name: fileName,
                  size: getBase64FileSize(withoutLogo),
                  type: 'application/pdf',
                },
              },
            ),
          );

          const resultDoc = await requestDoc.unwrap();

          return { data: resultDoc };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateDocumentEndTreatmentConsultation: builder.mutation<
      UserPatientProfileDocument,
      BaseInput
    >({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const employee = selectAuthUser(getState() as RootState);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const { content, title } = input;

          const resultPdf = await ServicePdf.generatePdfComplex({
            content,
          });

          const fileName = `summary_of_consultation__${new Date().toISOString()}.pdf`;

          const requestDoc = dispatch(
            apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate(
              {
                userPatientProfileID: input.userPatientProfileID,
                userEmployeeProfileID: String(employee.appUserID),
                isForm29: false,
                isCopy: false,
                fileName: title || 'unknown_content',
                documentURL: {
                  value: resultPdf.withLogo,
                  name: fileName,
                  size: getBase64FileSize(resultPdf.withLogo),
                  type: 'application/pdf',
                },
                documentForPrintURL: {
                  value: resultPdf.withoutLogo,
                  name: fileName,
                  size: getBase64FileSize(resultPdf.withoutLogo),
                  type: 'application/pdf',
                },
              },
            ),
          );

          const resultDoc = await requestDoc.unwrap();

          return { data: resultDoc };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateDocumentLetterToMedInsurance: builder.mutation<UserPatientProfileDocument, BaseInput>({
      queryFn: async (input, { dispatch, getState }) => {
        const { content, title, userPatientProfileID } = input;
        const employee = selectAuthUser(getState() as RootState);

        if (!employee) {
          throw new Error('Unauthorized');
        }

        const { withLogo, withoutLogo } = await ServicePdf.generatePdfComplex({
          content: content,
        });

        const fileName = `letter_to_med_insurance__${new Date().toISOString()}.pdf`;

        const requestDoc = dispatch(
          apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate({
            userPatientProfileID: userPatientProfileID,
            userEmployeeProfileID: String(employee.appUserID),
            isForm29: false,
            isCopy: false,
            fileName: title || 'unknown_content',
            documentURL: {
              value: withLogo,
              name: fileName,
              size: getBase64FileSize(withLogo),
              type: 'application/pdf',
            },
            documentForPrintURL: {
              value: withoutLogo,
              name: fileName,
              size: getBase64FileSize(withoutLogo),
              type: 'application/pdf',
            },
          }),
        );

        const resultDoc = await requestDoc.unwrap();

        return { data: resultDoc };
      },
    }),
    generateDocumentTravelLetter: builder.mutation<UserPatientProfileDocument, BaseInput>({
      queryFn: async ({ content, userPatientProfileID, title }, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const { withLogo, withoutLogo } = await ServicePdf.generatePdfComplex({
            content: content,
          });

          const fileName = `travel-letter__${new Date().toISOString()}.pdf`;

          const requestDoc = dispatch(
            apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate(
              {
                userPatientProfileID: userPatientProfileID,
                userEmployeeProfileID: String(employee.appUserID),
                isForm29: false,
                isCopy: false,
                fileName: title || 'unknown_content',
                documentURL: {
                  value: withLogo,
                  name: fileName,
                  size: getBase64FileSize(withLogo),
                  type: 'application/pdf',
                },
                documentForPrintURL: {
                  value: withoutLogo,
                  name: fileName,
                  size: getBase64FileSize(withoutLogo),
                  type: 'application/pdf',
                },
              },
            ),
          );

          const resultDoc = await requestDoc.unwrap();

          return { data: resultDoc };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateDocumentEmptyLetter: builder.mutation<UserPatientProfileDocument, BaseInput>({
      queryFn: async ({ content, userPatientProfileID, title }, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const { withLogo, withoutLogo } = await ServicePdf.generatePdfComplex({
            content: content,
          });

          const fileName = `empty-letter__${new Date().toISOString()}.pdf`;

          const requestDoc = dispatch(
            apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate(
              {
                userPatientProfileID: userPatientProfileID,
                userEmployeeProfileID: String(employee.appUserID),
                isForm29: false,
                isCopy: false,
                fileName: title || 'unknown_content',
                documentURL: {
                  value: withLogo,
                  name: fileName,
                  size: getBase64FileSize(withLogo),
                  type: 'application/pdf',
                },
                documentForPrintURL: {
                  value: withoutLogo,
                  name: fileName,
                  size: getBase64FileSize(withoutLogo),
                  type: 'application/pdf',
                },
              },
            ),
          );

          const resultDoc = await requestDoc.unwrap();

          return { data: resultDoc };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateDocumentHmoLetter: builder.mutation<UserPatientProfileDocument, BaseInput>({
      queryFn: async ({ content, userPatientProfileID, title }, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const { withLogo, withoutLogo } = await ServicePdf.generatePdfComplex({
            content: content,
          });

          const fileName = `hmo-letter__${new Date().toISOString()}.pdf`;

          const requestDoc = dispatch(
            apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate(
              {
                userPatientProfileID: userPatientProfileID,
                userEmployeeProfileID: String(employee.appUserID),
                isForm29: false,
                isCopy: false,
                fileName: title || 'unknown_content',
                documentURL: {
                  value: withLogo,
                  name: fileName,
                  size: getBase64FileSize(withLogo),
                  type: 'application/pdf',
                },
                documentForPrintURL: {
                  value: withoutLogo,
                  name: fileName,
                  size: getBase64FileSize(withoutLogo),
                  type: 'application/pdf',
                },
              },
            ),
          );

          const resultDoc = await requestDoc.unwrap();

          return { data: resultDoc };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    generateDocumentEndTreatmentDetails: builder.mutation<UserPatientProfileDocument, BaseInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const employee = selectAuthUser(state);

          if (!employee) {
            throw new Error('Unauthorized');
          }

          const { content, title } = input;

          const { withLogo, withoutLogo } = await ServicePdf.generatePdfComplex({
            content,
          });

          const fileName = `details_of_treatment__${new Date().toISOString()}.pdf`;

          const requestDoc = dispatch(
            apiUserPatientProfileDocuments.endpoints.createUserPatientProfileDocumentReport.initiate(
              {
                userPatientProfileID: input.userPatientProfileID,
                userEmployeeProfileID: String(employee.appUserID),
                isForm29: false,
                isCopy: false,
                fileName: title || 'unknown_content',
                documentURL: {
                  value: withLogo,
                  name: fileName,
                  size: getBase64FileSize(withLogo),
                  type: 'application/pdf',
                },
                documentForPrintURL: {
                  value: withoutLogo,
                  name: fileName,
                  size: getBase64FileSize(withoutLogo),
                  type: 'application/pdf',
                },
              },
            ),
          );

          const resultDoc = await requestDoc.unwrap();

          return { data: resultDoc };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
