import {
  apiUserPatientPrescriptions,
  ServicePatientPrescription,
  MakeWizardValidityItemsDraftInput,
  MakeWizardValidityItemsSignInput,
} from 'services/user-patient-profile-prescriptions';
import { apiPatientFormDocuments } from 'services/user-patient-profile-form-documents';
import { useMemo } from 'react';
import { apiFormDocuments } from 'services/form-documents';
import { useTranslate } from 'hooks/use-translate';
import { usePermissions } from 'hooks/use-permissions';

export const usePrescriptionCompletenessStepDraftOptions = (
  options: MakeWizardValidityItemsDraftInput,
) => {
  const { t } = useTranslate();
  const { healthSurveyCount, paymentDate, surveyExpiredDate } = options;

  const result = useMemo(() => {
    return ServicePatientPrescription.makeWizardValidityItemsDraft({
      healthSurveyCount: healthSurveyCount,
      paymentDate: paymentDate,
      surveyExpiredDate: surveyExpiredDate,
    });
  }, [healthSurveyCount, paymentDate, surveyExpiredDate]);

  const items = useMemo(() => {
    return result.map((item) => {
      return {
        title: t(item.labelKey),
        value: item.value,
        isValid: item.isValid,
      };
    });
  }, [result, t]);

  const isValid = useMemo(() => {
    return items.every(({ isValid }) => isValid);
  }, [items]);

  return { items, isValid };
};
export const usePrescriptionCompletenessStepDraft = (prescriptionID: string) => {
  const { data, isLoading } =
    apiUserPatientPrescriptions.useGetMedicalPrescriptionWizardDataQuery(prescriptionID);

  const { items, isValid } = usePrescriptionCompletenessStepDraftOptions({
    healthSurveyCount: data?.healthSurveyCount,
    paymentDate: data?.subscription?.paymentDate,
    surveyExpiredDate: data?.userPatientProfile?.surveyExpiredDate,
  });

  return { isLoading, items, isValid };
};

export const usePrescriptionCompletenessStepSignDocOptions = (
  options: Omit<MakeWizardValidityItemsSignInput, 'systemDocuments'>,
) => {
  const { tp, t } = useTranslate();
  const resultDocsWithDrugs = apiFormDocuments.useGetFormDocumentsWithRequiredDrugsQuery();
  const systemDocuments = resultDocsWithDrugs.data || [];

  const { prescriptionDrugs, prescriptionDocs, patientSignedDocs, patient } = options;
  const result = useMemo(() => {
    return ServicePatientPrescription.makeWizardValidityItemsSigned({
      systemDocuments: systemDocuments.map((doc) => ({
        id: String(doc.id),
        drugIDs: doc.drugIDs,
        title: String(doc.title),
        isUnder18: Boolean(doc.isUnder18),
      })),
      prescriptionDrugs,
      prescriptionDocs,
      patientSignedDocs,
      patient,
    });
  }, [systemDocuments, prescriptionDrugs, prescriptionDocs, patientSignedDocs, patient]);

  const items = useMemo(() => {
    return result.map((item) => {
      switch (item.type) {
        case 'regular': {
          return {
            ...item,
            title: t(item.labelKey),
          };
        }
        case 'doc': {
          return {
            ...item,
            title: item.docTitle,
            value: item.isValid
              ? String(item.value)
              : tp('wizard-doc-drug-validation', { drugs: item.drugs }),
          };
        }
        default: {
          throw new Error('unexpected-type');
        }
      }
    });
  }, [t, tp, result]);

  const isValid = useMemo(() => {
    return items.every(({ isValid }) => isValid !== false);
  }, [items]);

  return { items, isValid, isLoading: resultDocsWithDrugs.isLoading };
};
export const usePrescriptionCompletenessStepSignDoc = (prescriptionID: string) => {
  const resultPrescription =
    apiUserPatientPrescriptions.useGetMedicalPrescriptionWizardDataQuery(prescriptionID);
  const userPatientProfileID = resultPrescription.data?.userPatientProfileID || '';

  const dateOfBirth = resultPrescription.data?.userPatientProfile.dateOfBirth;

  const details = resultPrescription.data?.userPatientProfilePrescriptionDetails || [];
  const prescriptionDocs = resultPrescription.data?.userPatientProfileFormDocuments || [];

  const resultPatientSignedDocs =
    apiPatientFormDocuments.useGetUserPatientProfileDocumentsAllAlreadySignedQuery(
      userPatientProfileID,
      {
        skip: !userPatientProfileID,
      },
    );
  const patientSignedDocs = resultPatientSignedDocs.data || [];

  const options = useMemo(() => {
    return {
      prescriptionDrugs: details.map((detail) => ({
        id: String(detail.drugID),
        catalogName: String(detail.catalogName),
      })),
      prescriptionDocs: prescriptionDocs.map(({ signedDate }) => ({ signedDate })),
      patientSignedDocs: patientSignedDocs.map((doc) => ({
        id: String(doc.formDocument.id),
        type: doc.formDocument.type,
        documentExpiredDate: doc.documentExpiredDate ?? null,
      })),
      patient: { dateOfBirth },
    };
  }, [details, prescriptionDocs, patientSignedDocs, dateOfBirth]);

  const {
    isValid,
    items,
    isLoading: _isLoading,
  } = usePrescriptionCompletenessStepSignDocOptions(options);

  const isLoading = resultPrescription.isLoading || resultPatientSignedDocs.isLoading || _isLoading;

  return { isLoading, items, isValid };
};

export const usePrescriptionCanComplete = (prescriptionID: string) => {
  const sign = usePrescriptionCompletenessStepSignDoc(prescriptionID);
  const draft = usePrescriptionCompletenessStepDraft(prescriptionID);
  const isAllowToDeleteAnything = usePermissions('isAllowToDeleteAnything');

  const isValid = sign.isValid && draft.isValid;
  const canComplete = isValid || isAllowToDeleteAnything;
  const isLoading = sign.isLoading || draft.isLoading;

  const items = useMemo(() => [...draft.items, ...sign.items], [draft.items, sign.items]);

  return { canComplete, isValid, isLoading, items };
};
