import {
  API_USER_PATIENT_PROFILE_FORM_DOCUMENTS,
  GetUserPatientProfileDocumentsAlreadySignedItem,
  iUserPatientProfileFormDocument,
  UserPatientProfileGetActivitiesInput,
  UserPatientProfileGetActivitiesItem,
} from './models';
import { DynamicService } from 'utils/service';
import { makeFilterDateRange } from 'utils/app-helpers';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { PatchPartial } from 'utils/types';
import { convertToDate } from '../../utils/dates';

export * from './models';

class Service extends DynamicService<iUserPatientProfileFormDocument> {
  getActivities = async (input: UserPatientProfileGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;

    const {
      data: { value },
    } = await this.getAllDynamic<UserPatientProfileGetActivitiesItem>({
      select: ['id', 'updatedDate', 'formDocument.title', 'formDocumentPath'].join(','),
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('updatedDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'updatedDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.title,
      date: item.updatedDate,
      employee: null,
      download: item.formDocumentPath,
    }));
  };
  isDocumentOld(doc: Pick<iUserPatientProfileFormDocument, 'documentExpiredDate'>) {
    if (!doc.documentExpiredDate) return false;
    return convertToDate(doc.documentExpiredDate) < new Date();
  }
}

export const ServicePatientFormDocuments = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.POST,
  patch: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.PATCH,
  delete: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.DELETE,
});

export const apiPatientFormDocuments = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserPatientProfileDocumentsAllAlreadySigned: build.query({
      queryFn: async (userPatientProfileID: string) => {
        const result =
          await ServicePatientFormDocuments.getAllDynamic<GetUserPatientProfileDocumentsAlreadySignedItem>(
            {
              filter: [`userPatientProfileID=="${userPatientProfileID}"`, 'signedDate!=null'].join(
                '&&',
              ),
              select: [
                'id',
                'signedDate',
                'userPatientProfilePrescriptionID',
                'documentExpiredDate',
                'formDocumentPath',
                'new { formDocument.id, formDocument.title, formDocument.formDocumentCategoryID, formDocument.formDocumentCategory.FormDocumentCategoryType as type} as formDocument',
              ].join(','),
            },
          );
        return { data: result.data.value };
      },
    }),
    postUserPatientFormDocument: build.mutation({
      queryFn: async (input: Omit<iUserPatientProfileFormDocument, 'id'>) => {
        const result = await ServicePatientFormDocuments.post(input);
        return { data: result.data };
      },
      invalidatesTags: (_, __, arg) => {
        if (arg.userPatientProfilePrescriptionID) {
          return [
            { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg.userPatientProfilePrescriptionID },
          ];
        }
        return [];
      },
    }),
    patchUserPatientFormDocument: build.mutation({
      queryFn: async (input: PatchPartial<iUserPatientProfileFormDocument, 'id'>) => {
        return ServicePatientFormDocuments.patch(input);
      },
      invalidatesTags: (_, __, arg) => {
        if (arg.userPatientProfilePrescriptionID) {
          return [
            { type: RTK_TAGS.PATIENT_PRESCRIPTION, id: arg.userPatientProfilePrescriptionID },
          ];
        }
        return [];
      },
    }),
  }),
});
