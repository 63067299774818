import React, { memo, useCallback } from 'react';
import { Slider, ValueLabelProps, Tooltip } from '@material-ui/core';
import { CALENDAR_MEETING_END_HOURS, CALENDAR_MEETING_START_HOURS } from 'configs/const';
import { convertTimeStringFromNumber } from '../../helpers';
import style from './index.module.scss';
import { NativeScroll } from 'components/native-scroll';
import { Day, TimeLine } from '../day';
import { useTranslate } from 'hooks/use-translate';

const MARKS = [
  {
    value: 0,
    label: '00:00',
  },
  {
    value: CALENDAR_MEETING_START_HOURS,
    label: convertTimeStringFromNumber(CALENDAR_MEETING_START_HOURS),
  },
  {
    value: CALENDAR_MEETING_END_HOURS,
    label: convertTimeStringFromNumber(CALENDAR_MEETING_END_HOURS),
  },
  {
    value: 24,
    label: '24:00',
  },
];

const ValueLabelComponent = memo<ValueLabelProps>((props) => {
  return (
    <Tooltip open={props.open} enterTouchDelay={0} placement="top" title={props.value} arrow>
      {props.children}
    </Tooltip>
  );
});

type DayItem = {
  id: string;
  timeline: TimeLine[];
  date: Date;
  isEnd: boolean;
  isEndTotal: boolean;
};

type Props = {
  timeRange: number[];
  onChangeTimeRange: (value: number[]) => void;
  currentDate: Date;
  onSelectCurrentDate: (currentDate: Date) => void;
  days: DayItem[];
  onChose: (date: Date) => void;
};
export const SlotsView = memo<Props>(
  ({ currentDate, onSelectCurrentDate, days, onChose, timeRange, onChangeTimeRange }) => {
    const { t } = useTranslate();

    const handleChangeTimeRange = useCallback(
      (_, value) => {
        onChangeTimeRange(value);
      },
      [onChangeTimeRange],
    );

    return (
      <div className={style.root}>
        <div className={style.head}>
          <div className={style.title}>{t('time-range')}:</div>
          <div className={style.slide}>
            <Slider
              valueLabelFormat={convertTimeStringFromNumber}
              ValueLabelComponent={ValueLabelComponent}
              valueLabelDisplay="on"
              value={timeRange}
              min={0}
              max={24}
              step={1}
              marks={MARKS}
              onChange={handleChangeTimeRange}
            />
          </div>
        </div>
        <div className={style.content}>
          <NativeScroll className={style.scroll}>
            <div className={style.days}>
              {days.map(({ date, timeline, id, isEnd, isEndTotal }) => (
                <Day
                  key={id}
                  date={date}
                  currentDate={currentDate}
                  timeline={timeline}
                  onChose={onChose}
                  onSelectCurrentDate={onSelectCurrentDate}
                  isEnd={isEnd}
                  isEndTotal={isEndTotal}
                />
              ))}
            </div>
          </NativeScroll>
        </div>
      </div>
    );
  },
);
