import React, { useCallback, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { SmsMessageModel } from '../../models';
import { useTranslate } from 'hooks/use-translate';
import { useFieldProps } from 'hooks';
import { calcHtmlTemplate } from 'utils';
import { validateRule } from 'AurionCR/components/formV2';
import { AppInput } from 'components/app-input';
import { useSourceMessageTemplates, MessageTemplate } from 'hooks/use-source-message-templates';
import { AppInputTemplate } from 'components/app-input-template';

interface Props<TP> {
  isLoading: boolean;
  templatesPayload: TP;
}

export const SendSmsMessageForm = <TP,>({ isLoading, templatesPayload }: Props<TP>) => {
  const { t } = useTranslate();

  const { errors, control, setValue } = useFormContext<SmsMessageModel>();

  const sourceTemplates = useSourceMessageTemplates();

  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const messageRef = useRef<HTMLInputElement>(null);
  const onSelectTemplate = useCallback(
    (value: MessageTemplate | null) => {
      const message = calcHtmlTemplate(value?.message || '', templatesPayload ?? undefined);
      setValue('message', message);
      messageRef.current?.focus();
    },
    [setValue, templatesPayload],
  );

  const isDisabledTemplate = sourceTemplates.data.length === 0 || isLoading;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'toPhoneNumber'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <AppInput
              {...getFieldProps(renderProps)}
              label={t('mobile-phone')}
              disabled={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'message'}
          rules={validateRule('required,minLength')}
          render={(renderProps) => {
            return (
              <AppInputTemplate
                {...getFieldProps(renderProps)}
                onSelectTemplate={onSelectTemplate}
                disabled={isLoading}
                inputRef={messageRef}
                templates={sourceTemplates.data}
                isLoading={sourceTemplates.loading}
                disabledTemplate={isDisabledTemplate}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
