import { FormDocument } from 'services/form-documents';
import { DateValue } from 'utils/dates';
import { FixServerObject } from 'utils/types';
import { PIPELINE_VARIABLES } from 'configs/const';
import { Notebook } from 'services/notebook';
import { FormDocumentCategoryType } from '../form-document-categories';

const _API = 'UserPatientProfileFormDocuments';

export const API_USER_PATIENT_PROFILE_FORM_DOCUMENTS = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: (data: Pick<iUserPatientProfileFormDocument, 'id'>) => `${_API}/Patch/${data.id}`,
  DELETE: (data: Pick<iUserPatientProfileFormDocument, 'id'>) => `${_API}/Delete/${data.id}`,
};

export interface iUserPatientProfileFormDocument
  extends FixServerObject<Components.Schemas.UserPatientProfileFormDocument, 'id'> {
  updatedDate?: string;
}

export interface GetUserPatientProfileDocumentsAlreadySignedItem
  extends Pick<
    iUserPatientProfileFormDocument,
    | 'id'
    | 'signedDate'
    | 'formDocument'
    | 'documentExpiredDate'
    | 'formDocumentPath'
    | 'userPatientProfilePrescriptionID'
  > {
  formDocument: Pick<FormDocument, 'id' | 'title' | 'formDocumentCategoryID'> & {
    type: FormDocumentCategoryType;
  };
}

export interface UserPatientProfileGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}

export interface UserPatientProfileGetActivitiesItem
  extends Pick<iUserPatientProfileFormDocument, 'id' | 'updatedDate' | 'formDocumentPath'> {
  title: FormDocument['title'];
}

export const createPatientDocumentLinkInnerLink = (
  data: Pick<iUserPatientProfileFormDocument, 'id'>,
) => {
  return `/patient/documents/${data.id}/sign`;
};
export const createPatientDocumentLink = (data: Pick<iUserPatientProfileFormDocument, 'id'>) => {
  return `${PIPELINE_VARIABLES.PATIENT_APP_URL}${createPatientDocumentLinkInnerLink(data)}`;
};
export const createPatientSurveyLink = (data: Pick<Notebook, 'id'>) => {
  return `/patient/survey/${data.id}`;
};
