import { TemplateDate } from 'components/templates/template-date';
import { Unset } from 'utils/types';
import React from 'react';
import { convertToDate } from 'utils/dates';
import styles from './index.module.scss';
import clsx from 'clsx';
import { APP_FORMAT_DATE } from 'configs/const';

interface Props {
  date: Unset;
}
export const ColumnDocumentExpiredDate: React.FC<Props> = ({ date }) => {
  const isExpired = convertToDate(date) < new Date();

  return (
    <TemplateDate
      date={date}
      className={clsx(isExpired && styles.expired)}
      format={APP_FORMAT_DATE}
    />
  );
};
