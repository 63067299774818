import React from 'react';
import {
  API_USER_PATIENT_PROFILE_FORM_DOCUMENTS,
  createPatientDocumentLink,
  iUserPatientProfileFormDocument,
} from 'services/user-patient-profile-form-documents';
import { API_FORM_DOCUMENTS } from 'services/form-documents';
import { ControlButtonSmsSignDoc } from 'components/app-grid/control-button-sms-sign-doc';
import TemplateDownloadPrivate from 'components/templates/template-download-private';
import { TemplateLinkCopy } from 'components/templates/template-link-copy';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { GridMenuSignedDocumentsBackDoor } from 'components/dialog-patient/grids';
import { excelTemplateDate } from 'utils/dates';
import { DefaultGridButtonExportToExcel } from 'components/data-grid/model';
import { ColumnDocumentExpiredDate } from './components/column-document-expired-date';

export default (userPatientProfileID: string): any => ({
  id: 'grid_user-patient-profile-form-documents',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.GET_ALL_DYNAMIC,
      filters: `userPatientProfileID=="${userPatientProfileID}"`,
      selected: [
        'new { userPatientProfile.firstName, userPatientProfile.lastName, userPatientProfile.mobilePhone } as patient',
        'userPatientProfileID',
      ].join(','),
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_FORM_DOCUMENTS.DELETE,
    },
    sortable: {
      field: 'expiredDate',
      order: 'desc',
    },
  },
  toolbar: {
    title: '',
    excelTitle: 'signed-documents',
    filters: [
      {
        field: 'formDocumentID',
        options: {
          type: 'select',
          label: 'form-document',
          source: `${API_FORM_DOCUMENTS.GET_ALL_DYNAMIC}?Select=id,title&orderBy=title`,
          style: { minWidth: '35rem' },
        },
      },
    ],
    controls: null,
    controlsDefault: {
      new: null,
      resetFilters: null,
      export: DefaultGridButtonExportToExcel,
    },
  },
  controls: [
    {
      type: 'custom',
      template: ControlButtonSmsSignDoc,
    },
  ],
  controlsDropDown: [
    {
      type: 'custom',
      template: GridMenuSignedDocumentsBackDoor,
    },
    {
      type: 'delete',
      title: 'delete',
      permission: permissionKey('isAllowToDeleteAnything'),
    },
  ],
  columns: [
    {
      title: 'form-document-title',
      field: 'formDocument.title as title',
      sortable: 'formDocument.title',
    },
    {
      title: 'form-document-category',
      field: 'formDocument.formDocumentCategory.title as category',
      sortable: 'formDocument.formDocumentCategory.title',
    },
    {
      title: 'download-doc',
      field: 'formDocumentPath',
      sortable: false,
      template: (data: iUserPatientProfileFormDocument) => (
        <TemplateDownloadPrivate documentURL={data.formDocumentPath} />
      ),
    },
    {
      title: 'expired-date',
      field: 'expiredDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
        permission: permissionKey('isAllowToEditPermissions'),
      },
      tdClasses: 'mobile-no-wrap',
      valueTemplate: (data: iUserPatientProfileFormDocument) => excelTemplateDate(data.expiredDate),
    },
    {
      title: 'signed-date',
      field: 'signedDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
      valueTemplate: (data: iUserPatientProfileFormDocument) => excelTemplateDate(data.signedDate),
      tdClasses: 'mobile-no-wrap',
    },
    {
      title: 'document-expired-date',
      field: 'documentExpiredDate',
      template: (data: iUserPatientProfileFormDocument) => (
        <ColumnDocumentExpiredDate date={data.documentExpiredDate} />
      ),
      editor: {
        type: 'date',
      },
      tdClasses: 'mobile-no-wrap',
      valueTemplate: (data: iUserPatientProfileFormDocument) =>
        excelTemplateDate(data.documentExpiredDate),
    },
    {
      title: 'patient-path',
      sortable: false,
      template: (data: iUserPatientProfileFormDocument) => (
        <TemplateLinkCopy
          href={createPatientDocumentLink(data)}
          StackProps={{ style: { maxWidth: '20rem' } }}
        />
      ),
      tdClasses: 'boolean',
    },
    {
      title: 'is-expired',
      field: '(expiredDate <= DateTime.Now) as isExpired',
      sortable: false,
      template: 'boolean',
      tdClasses: 'boolean',
    },
    {
      title: 'active',
      field: 'isActive',
      template: 'boolean',
    },
  ],
});
