import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useSource } from 'AurionCR/components';
import { validateRule } from 'AurionCR/components/formV2';
import { DialogHeading } from 'components/dialog-title';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useCurrentUser } from 'components/hooks';
import { API_SMS_CONTENTS, SMSContents } from 'services/sms-contents';
import { useAppSelector } from 'store';
import { useTranslate } from 'hooks/use-translate';
import { useEffectError, useFieldProps, useMutationDynamic } from 'hooks';
import { AppInput } from 'components/app-input';
import { calcHtmlTemplate } from 'utils';
import { UserPatientProfile } from 'services/user-patient-profile';
import { api, generateDynamicQuery } from 'utils/service';
import { Left } from 'utils/types';
import { mergeFilters } from 'utils/dynamic-helpers';
import { selectLanguageID } from 'store/languages';
import { AppInputTemplate } from '../app-input-template';

type TemplateOption = Pick<SMSContents, 'id' | 'title' | 'message' | 'smsKey'>;
const useSourceTemplates = () => {
  const { userEmployeeProfilePermission } = useCurrentUser();
  const languageID = useAppSelector(selectLanguageID);
  const userEmployeeProfilePermissionID = userEmployeeProfilePermission?.id;

  const query = useMemo(
    () =>
      generateDynamicQuery({
        select: ['id', 'title', 'message', 'smsKey'].join(','),
        orderBy: ['title asc'].join(','),
        filter: mergeFilters(
          `languageID=="${languageID}"`,
          userEmployeeProfilePermissionID &&
            `userEmployeeProfilePermissionSmsContents.any(k =>k.userEmployeeProfilePermissionID=="${userEmployeeProfilePermissionID}")`,
        ).join('&&'),
      }),
    [languageID, userEmployeeProfilePermissionID],
  );

  return useSource<TemplateOption>(`${API_SMS_CONTENTS.GET_ALL_DYNAMIC}?${query}`);
};

export interface SmsSenderModel {
  toPhoneNumber: string;
  userPatientProfileID?: string;
  leadID?: string;
  message?: string;
}

export interface SmsSenderTemplatesPayload {
  meetingFromTime: string;
  meetingFromDate: string;

  meetingToTime: string;
  meetingToDate: string;

  patient: Pick<UserPatientProfile, 'firstName' | 'lastName'>;
  lead: { firstName: string; lastName: string };
  currentEmployee: Pick<UserPatientProfile, 'firstName' | 'lastName'>;
}

interface FormSMSSenderProps {
  isLoading: boolean;
  templatesPayload?: Partial<SmsSenderTemplatesPayload> | null;
}

type FormModel = Left<SmsSenderModel>;
export const FormSMSSender: React.FC<FormSMSSenderProps> = ({ isLoading, templatesPayload }) => {
  const { control, errors, register, setValue } = useFormContext<FormModel>();
  const { t } = useTranslate();

  const sourceTemplates = useSourceTemplates();
  const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

  const messageRef = useRef<HTMLInputElement>(null);
  const onSelectTemplate = useCallback(
    (value: TemplateOption | null) => {
      const message = calcHtmlTemplate(value?.message || '', templatesPayload ?? undefined);
      setValue('message', message);
      messageRef.current?.focus();
    },
    [setValue, templatesPayload],
  );

  const isDisabledTemplate = sourceTemplates.data.length === 0 || isLoading;

  return (
    <>
      {register && <input {...register('leadID')} type="hidden" />}
      {register && <input {...register('userPatientProfileID')} type="hidden" />}
      <Controller
        control={control}
        name={'toPhoneNumber'}
        rules={validateRule('required')}
        render={(renderProps) => (
          <AppInput
            {...getFieldProps(renderProps)}
            label={t('mobile-phone')}
            disabled={isLoading}
          />
        )}
      />
      <Controller
        name={'message'}
        rules={validateRule('required,minLength')}
        render={(renderProps) => {
          return (
            <AppInputTemplate
              {...getFieldProps(renderProps)}
              onSelectTemplate={onSelectTemplate}
              disabled={isLoading}
              disabledTemplate={isDisabledTemplate}
              templates={sourceTemplates.data}
              isLoading={sourceTemplates.loading}
            />
          );
        }}
      />
    </>
  );
};

type TemplatePayload = Partial<SmsSenderTemplatesPayload> | undefined | null;

interface Props {
  url: string;
  initData: SmsSenderModel;
  templatePayload: TemplatePayload;
  onClose: () => void;
  onFormHandle: (formData: SmsSenderModel) => void;
}

export interface SmsSenderHandle {
  setFormData: <K extends keyof SmsSenderModel>(key: K, value: SmsSenderModel[K]) => void;
}

export const SmsSender = forwardRef<SmsSenderHandle, Props>(
  ({ initData, onClose, onFormHandle, url, templatePayload }, ref) => {
    const { t } = useTranslate();

    const onSubmit = useCallback(
      (formData: SmsSenderModel) => {
        return api.post(url, formData).then(() => {
          onFormHandle(formData);
        });
      },
      [url, onFormHandle],
    );

    const defaultValues: SmsSenderModel = useMemo(() => {
      return {
        leadID: undefined,
        userPatientProfileID: undefined,
        message: '',
        ...initData,
      };
    }, [initData]);

    const formMethods = useForm({
      defaultValues,
    });
    const { handleSubmit, setValue } = formMethods;
    const [triggerSubmit, { error, isLoading }] = useMutationDynamic(onSubmit);
    useEffectError(error);

    useImperativeHandle(
      ref,
      () => ({
        setFormData: (key, value) => {
          setValue(key, value);
        },
      }),
      [setValue],
    );

    return (
      <Dialog open={true} onClose={onClose} disableEscapeKeyDown={true} fullWidth maxWidth={'sm'}>
        <DialogHeading isLoading={isLoading} title={t('sms-message')} onClose={onClose} />
        <DialogContent>
          <FormProvider {...formMethods}>
            <FormSMSSender isLoading={isLoading} templatesPayload={templatePayload} />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t('close')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSubmit(triggerSubmit)}
          >
            {t('send')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
