const API_ENDPOINT = 'DocumentTemplates';

export const API_DOCUMENT_TEMPLATES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<DocumentTemplate, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<DocumentTemplate, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface DocumentTemplate {
  id: string;
  isActive: true;
  title: string;
  contentKey: string;
  html: string;
  rowIndex?: number;
}

export enum CONTENT_KEYS {
  GIMEL_29 = 'GIMEL-29',
  PRESCRIPTION = 'prescription',
  PRESCRIPTION_PRINT = 'prescription_print',
  TREATMENT_ENG = 'summary_of_treatment_en',
  TREATMENT_HEB_MAN = 'summary_of_treatment_he_man',
  TREATMENT_HEB_WOMAN = 'summary_of_treatment_he_woman',
  SUMMARY_OF_CONSULTATION = 'summary_of_consultation_he',
  LETTER_TO_MED_INSURANCE = 'letter_to_med_insurance_he',
  DETAILS_OF_TREATMENT = 'details_of_treatment_he',
  DIETITIAN_REPORT = 'dietitian_report',
  TRAVEL_LETTER = 'travel-latter',
  EMPTY_LETTER = 'empty-latter',
  HMO_LETTER = 'hmo-letter',
}
