import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { useOpen } from 'AurionCR/components/hooks';
import AddIcon from '@material-ui/icons/Add';
import { composeFunctions } from 'utils';
import { BaseCreateComponentProps } from '../../models';
import { DialogCreateDocEndOfTreatment } from '../dialog-create-doc-end-of-treatment';
import { DialogCreateDocSummaryOfConsultation } from '../dialog-create-doc-summary-of-consultation';
import { DialogCreateDocLetterToMedInsurance } from '../dialog-create-doc-letter-to-med-insurance';
import { DialogCreateDocDietitianReport } from '../dialog-create-doc-dietitian-report';
import { DialogCreateDocDetailsOfTreatment } from '../dialog-create-doc-details-of-treatment';
import { enumToArray } from 'utils/other';
import { DialogCreateDocTravelLetter } from '../dialog-create-doc-travel-letter';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { DialogCreateDocEmptyLetter } from '../dialog-create-doc-empty-letter';
import { DialogCreateDocHmoLetter } from '../dialog-create-doc-hmo-letter';

const useFetchPatient = apiUserPatientProfile.useGetPatientForGeneralFormQuery;

interface Props {
  userPatientProfileID: string;
  onRefresh?: () => void;
}

enum MENU_ITEMS_NAMES {
  SUMMARY_OF_CONSULTATION = 'summary-of-consultation',
  LETTER_TO_MED_INSURANCE = 'letter-to-med-insurance',
  DETAILS_OF_TREATMENT = 'details-of-treatment',
  DIETITIAN_REPORT = 'dietitian-report',
  END_OF_TREATMENT = 'end-of-treatment',
  TRAVEL_LETTER = 'travel-letter',
  EMPTY_LETTER = 'empty-letter',
  HMO_LETTER = 'hmo-letter',
}

const MAP_COMPONENTS: Record<
  MENU_ITEMS_NAMES,
  React.ComponentType<BaseCreateComponentProps> | null
> = {
  [MENU_ITEMS_NAMES.SUMMARY_OF_CONSULTATION]: DialogCreateDocSummaryOfConsultation,
  [MENU_ITEMS_NAMES.LETTER_TO_MED_INSURANCE]: DialogCreateDocLetterToMedInsurance,
  [MENU_ITEMS_NAMES.DETAILS_OF_TREATMENT]: DialogCreateDocDetailsOfTreatment,
  [MENU_ITEMS_NAMES.DIETITIAN_REPORT]: DialogCreateDocDietitianReport,
  [MENU_ITEMS_NAMES.END_OF_TREATMENT]: DialogCreateDocEndOfTreatment,
  [MENU_ITEMS_NAMES.TRAVEL_LETTER]: DialogCreateDocTravelLetter,
  [MENU_ITEMS_NAMES.EMPTY_LETTER]: DialogCreateDocEmptyLetter,
  [MENU_ITEMS_NAMES.HMO_LETTER]: DialogCreateDocHmoLetter,
};

export const ButtonAddNew: React.FC<Props> = ({ userPatientProfileID, onRefresh }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { isOpen, handleClose, handleToggle } = useOpen();
  const { t } = useTranslate();

  const [menuItem, setMenuItem] = useState<MENU_ITEMS_NAMES | null>();
  const MenuComponent = menuItem ? MAP_COMPONENTS[menuItem] : null;

  const factoryClickMenu = useCallback((menu: MENU_ITEMS_NAMES) => {
    return () => setMenuItem(menu);
  }, []);
  const onMenuComponentClose = useCallback(() => {
    setMenuItem(null);
  }, []);
  const onMenuComponentDone = useCallback(() => {
    setMenuItem(null);
    onRefresh && onRefresh();
  }, [onRefresh]);

  const { data: patient } = useFetchPatient(userPatientProfileID);

  const getDisabled = useCallback(
    (type: MENU_ITEMS_NAMES) => {
      if (type !== MENU_ITEMS_NAMES.END_OF_TREATMENT) {
        return false;
      }
      if (!patient) return true;
      return patient.doNotAllowEndOfTreatmentLetter === true;
    },
    [patient],
  );

  const menuItems = useMemo(() => {
    return enumToArray(MENU_ITEMS_NAMES)
      .map(({ id }) => ({ id, name: t(id), disabled: getDisabled(id) }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [t, getDisabled]);

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        ref={ref}
        color={'primary'}
        variant={'contained'}
        onClick={handleToggle}
      >
        {t('add-new')}
      </Button>
      <Menu
        anchorEl={ref.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            onClick={composeFunctions(factoryClickMenu(item.id), handleClose)}
            disabled={item.disabled}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
      {MenuComponent && (
        <MenuComponent
          userPatientProfileID={userPatientProfileID}
          onClose={onMenuComponentClose}
          onDone={onMenuComponentDone}
        />
      )}
    </>
  );
};
