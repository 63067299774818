import React, { useCallback, useMemo, useRef } from 'react';
import { useFormContext, Controller, ControllerRenderProps, useController } from 'react-hook-form';
import {
  END_TREATMENT_LANGUAGE,
  SubscriptionEndTreatment,
} from 'services/user-patient-profile-subscriptions';
import { Grid } from '@material-ui/core';
import { useEffectError, useFieldProps } from 'hooks';
import { AppRadioGroup } from 'components/app-radio-group';
import { enumToArray, fieldToLabelKey } from 'utils/other';
import { Left } from 'utils/types';
import { useTranslate } from 'hooks/use-translate';
import { AppWeightInput } from 'components/app-weight-input';
import { AppInput } from 'components/app-input';
import { apiEndOfTreatmentRecommendationTemplate } from 'services/end-of-treatment-recommendation-templates';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { convertToDate, dateFormat } from 'utils/dates';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { AppPrescriptionDrugsAreaInput } from 'components/app-prescription-drugs-area-input';
import { AppChipAreaInput } from 'components/app-chip-area-input';
import { AppInputTemplate } from 'components/app-input-template';

const useSourceTemplatesQuery =
  apiEndOfTreatmentRecommendationTemplate.useGetEndOfTreatmentRecommendationTemplatesSourceQuery;

const useFetchPrescriptionsQuery =
  apiUserPatientPrescriptions.useGetMedicalPrescriptionsWithDrugsQuery;

type Template = {
  title?: string | null | undefined;
};
interface Props {
  isLoading: boolean;
  userPatientProfileID: string;
}

type FormModel = Left<SubscriptionEndTreatment>;
export const FormEndTreatment: React.FC<Props> = ({ isLoading, userPatientProfileID }) => {
  const { t } = useTranslate();
  const { control, errors, setValue, getValues } = useFormContext<FormModel>();
  const getFieldProps = useFieldProps({ errors, disabled: isLoading });
  const languageOptions = useMemo(
    () =>
      enumToArray(END_TREATMENT_LANGUAGE).map((item) => {
        const labelKey = String(item.title).toLowerCase();
        return {
          value: item.id,
          label: t(fieldToLabelKey(labelKey)),
        };
      }),
    [t],
  );
  const messageRef = useRef<HTMLInputElement>(null);

  const sourceTemplates = useSourceTemplatesQuery();
  useEffectError(sourceTemplates.error);

  const onSelectTemplate = useCallback(
    (value: Template | null) => {
      if (!value) return;

      const message = getValues('doctorRecommendation') || '';

      setValue(
        'doctorRecommendation',
        [message.trim(), `${value.title} `].filter(Boolean).join('\n'),
      );
      messageRef.current?.focus();
    },
    [setValue, getValues],
  );

  const sourcePrescriptions = useFetchPrescriptionsQuery(userPatientProfileID);
  useEffectError(sourcePrescriptions.error);
  const prescriptionOptions = useMemo(() => {
    return (sourcePrescriptions.data || []).map((prescription) => {
      return {
        id: String(prescription.id),
        title: dateFormat(prescription.entryDate, APP_FORMAT_DATE_TIME),
        isCompleted: !!prescription.isCompleted,
        entryDate: convertToDate(prescription.entryDate),
        items: prescription.drugs.map((drug) => {
          return { id: String(drug.id), title: String(drug.drugNameEng) };
        }),
      };
    });
  }, [sourcePrescriptions.data]);
  const controlDrugs = useController({ control, name: 'prescribedMedications' });

  const renderWeight = (renderProps: ControllerRenderProps) => (
    <AppWeightInput {...getFieldProps(renderProps)} disableClearable disabled={isLoading} />
  );
  const renderBMI = (renderProps: ControllerRenderProps) => (
    <AppInput
      {...getFieldProps(renderProps)}
      disableClearable
      disabled={isLoading}
      type={'number'}
    />
  );

  return (
    <Grid container spacing={1}>
      <Grid md={6} lg={5} item>
        <AppPrescriptionDrugsAreaInput
          {...getFieldProps(controlDrugs.field)}
          label={t('prescriptions')}
          options={prescriptionOptions}
          disabled={isLoading}
        />
      </Grid>
      <Grid md={6} lg={7} item>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AppChipAreaInput {...getFieldProps(controlDrugs.field)} disabled={isLoading} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller control={control} name={'startingWeight'} render={renderWeight} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller control={control} name={'startingBMI'} render={renderBMI} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller control={control} name={'currentWeight'} render={renderWeight} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller control={control} name={'currentBMI'} render={renderBMI} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name={'doctorRecommendation'}
              render={(renderProps) => {
                return (
                  <AppInputTemplate
                    {...getFieldProps(renderProps)}
                    inputRef={messageRef}
                    onSelectTemplate={onSelectTemplate}
                    templates={sourceTemplates.data || []}
                    isLoading={sourceTemplates.isLoading}
                    disabled={isLoading}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={true}>
            <Controller
              name={'period'}
              control={control}
              render={(renderProps) => <AppInput {...getFieldProps(renderProps)} />}
            />
          </Grid>
          <Grid item xs={'auto'}>
            <Controller
              name={'language'}
              control={control}
              render={(renderProps) => (
                <AppRadioGroup row {...getFieldProps(renderProps)} options={languageOptions} />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
