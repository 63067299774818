import React, { memo, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import style from './index.module.scss';
import { Button, ButtonBase } from '@material-ui/core';
import { format, isSameDay } from 'date-fns';
import { useTranslate } from 'hooks/use-translate';
import { APP_FORMAT_DATE } from 'configs/const';
import { usePermissions } from 'hooks/use-permissions';

interface TimeSlotProps {
  isAvailable: boolean;
  isReserved: boolean;
  isInClinic: boolean;
  isPast: boolean;
  isPreferred: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  isAllowMultiple: boolean;
  disabled?: boolean;
}

export const TimeSlot = memo<TimeSlotProps>(
  ({
    isAvailable,
    isAllowMultiple,
    isPast,
    isInClinic,
    isReserved,
    isPreferred,
    onClick,
    children,
    disabled: _disabled,
  }) => {
    const { t } = useTranslate();

    const title = useMemo(() => {
      return [t(isInClinic ? 'clinical-meeting' : 'online-meeting'), isReserved && t('reserved')]
        .filter(Boolean)
        .join(' | ');
    }, [t, isInClinic, isReserved]);

    const status = useMemo(() => {
      if (!isAvailable) {
        return 'unavailable';
      }
      if (isReserved) {
        return 'reserved';
      }
      if (isInClinic) {
        return 'clinical';
      }
      return 'online';
    }, [isAvailable, isReserved, isInClinic]);

    const disabled = useMemo(() => {
      if (_disabled) return true;
      if (isPast) {
        return true;
      }

      if (status === 'reserved') {
        return !isAllowMultiple;
      }

      return false;
    }, [isPast, status, isAllowMultiple, _disabled]);

    return (
      <div
        className={clsx(style.item, style[status], {
          [style.disabled]: disabled,
          [style.preferred]: isAvailable && isPreferred,
        })}
      >
        <Button
          variant={'contained'}
          fullWidth
          className={style.btn}
          onClick={isPast ? undefined : onClick}
          disableRipple={isPast || disabled || !isAvailable}
          disabled={isPast || disabled || !isAvailable}
          title={title}
        >
          {children}
        </Button>
      </div>
    );
  },
);

export interface TimeLine {
  id: string;
  decimal: number;
  isAvailable: boolean;
  isReserved: boolean;
  isInClinic: boolean;
  isPast: boolean;
  isPreferred: boolean;
}
interface DayProps {
  currentDate: Date;
  date: Date;
  isEnd: boolean;
  isEndTotal: boolean;
  onSelectCurrentDate: (date: Date) => void;
  timeline: TimeLine[];
  onChose: (date: Date) => void;
}
export const Day = memo<DayProps>(
  ({ currentDate, timeline, date, onChose, onSelectCurrentDate, isEnd, isEndTotal }) => {
    const onClickDate = useCallback(() => {
      onSelectCurrentDate(date);
    }, [onSelectCurrentDate, date]);

    const { title, subtitle } = useMemo(
      () => ({
        title: format(date, APP_FORMAT_DATE),
        subtitle: format(date, 'EEEE'),
      }),
      [date],
    );

    const factoryClick = useCallback(
      (id: string) => {
        return () => {
          return onChose(new Date(format(date, 'yyyy-MM-dd') + 'T' + id));
        };
      },
      [onChose, date],
    );

    const isToday = useMemo(() => {
      return isSameDay(new Date(), date);
    }, [date]);

    const isSelected = useMemo(() => {
      return isSameDay(date, currentDate);
    }, [date, currentDate]);

    const isAllowToSetupMultipleMeetings = usePermissions('isAllowToSetupMultipleMeetings');

    const disabledByStatus = false;

    return (
      <div className={style.root}>
        <ButtonBase
          component={'div'}
          className={clsx(style.title, {
            [style.titleToday]: isToday,
            [style.titleSelected]: isSelected,
            [style.titleEnd]: isEnd,
            [style.titleEndTotal]: isEndTotal,
          })}
          onClick={onClickDate}
        >
          <strong>{title}</strong>
          {subtitle}
        </ButtonBase>
        {timeline.map((slot) => (
          <TimeSlot
            key={slot.id}
            isAvailable={slot.isAvailable}
            isInClinic={slot.isInClinic}
            isReserved={slot.isReserved}
            isPast={slot.isPast}
            isPreferred={slot.isPreferred}
            isAllowMultiple={isAllowToSetupMultipleMeetings}
            onClick={factoryClick(slot.id)}
            disabled={disabledByStatus}
          >
            {slot.id}
          </TimeSlot>
        ))}
      </div>
    );
  },
);
