import { GridControlsTemplateProps } from 'models/grid';
import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { GridModel } from '../helpers';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useClone } from '../hooks';
import { useDataGridTasks } from 'components/data-grid/hooks';

interface Props extends GridControlsTemplateProps<GridModel> {
  userPatientProfileSessionID: string;
}

export const ControlClone: React.FC<Props> = ({ userPatientProfileSessionID, data }) => {
  const { onTasks } = useDataGridTasks();
  const onSuccess = useCallback(() => {
    onTasks([['getData']]);
  }, [onTasks]);

  const [perform, { isLoading }] = useClone({
    userPatientProfileSessionID,
    drugID: data.drugID ?? '',
    dosageForm: data.dosageForm ?? '',
    onSuccess,
  });

  const { t } = useTranslate();

  if (data.userPatientProfileSessionID === userPatientProfileSessionID) {
    return null;
  }

  return (
    <Button
      color={'primary'}
      startIcon={<FileCopyIcon fontSize={'small'} />}
      onClick={perform}
      disabled={isLoading}
    >
      {t('clone')}
    </Button>
  );
};
