import React, { useMemo } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import {
  apiUserPatientProfileActivities,
  UserPatientProfileActivityLastWeightInfo,
} from 'services/user-patient-profile-activities';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { useOpen } from 'AurionCR/components/hooks';
import { useTranslate } from 'hooks/use-translate';
import { Skeleton } from '@material-ui/lab';
import { dateFormat } from 'utils/dates';
import { schemaChangeLogs } from 'modules/change-log';

interface TooltipInfoProps {
  info: UserPatientProfileActivityLastWeightInfo | null;
  isLoading: boolean;
}
const TooltipInfo: React.FC<TooltipInfoProps> = ({ isLoading, info }) => {
  const { t } = useTranslate();
  const infoItem = useMemo(() => {
    if (!info) return undefined;
    try {
      const res = schemaChangeLogs.cast(JSON.parse(info.changes), {
        stripUnknown: true,
        assert: false,
      }).fields;
      return res.find(({ field }) => field === 'weight');
    } catch {
      return undefined;
    }
  }, [info]);

  const renderValue = (value: React.ReactNode) => {
    if (isLoading) return <Skeleton width={50} />;
    if (!info) return '--';
    return value ?? '--';
  };

  const rows = [
    { title: t('entry-date'), value: dateFormat(info?.entryDate) },
    { title: t('remarks'), value: info?.remarks },
    { title: t('employee'), value: info?.userEmployeeProfile?.fullName },
    { title: t('previous'), value: infoItem?.fromValue },
  ];

  return (
    <table>
      <tbody>
        {rows.map((row, i) => {
          return (
            <tr key={i}>
              <td>
                <Typography variant={'body1'} component={'span'}>
                  {row.title}
                </Typography>
                :
              </td>
              <td>
                <Typography variant={'body2'} component={'span'}>
                  {renderValue(row.value)}
                </Typography>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const useFetchInfo = apiUserPatientProfileActivities.useGetUserPatientProfileActivityWeightQuery;

interface Props {
  userPatientProfileID: string;
}
export const IconPatientWeightInfo: React.FC<Props> = ({ userPatientProfileID }) => {
  const { isOpen, handleOpen, handleClose } = useOpen();
  const { data, isFetching } = useFetchInfo(userPatientProfileID, { skip: !isOpen });

  return (
    <Tooltip
      title={<TooltipInfo isLoading={isFetching} info={data ?? null} />}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <IconButton size={'small'}>
        <InfoIcon color={'action'} />
      </IconButton>
    </Tooltip>
  );
};
