import { Subscription } from '../subscription';
import { convertToDate, DateValue, isDateLike } from 'utils/dates';
import * as yup from 'yup';
import { InferType } from 'yup';
import { transformToDateISO } from 'utils/transformers';
import { set } from 'date-fns';

const API_ENDPOINT = 'UserPatientProfileSubscriptions';

export const API_USER_PATIENT_PROFILE_SUBSCRIPTIONS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileSubscription, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileSubscription, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface UserPatientProfileSubscription
  extends Components.Schemas.UserPatientProfileSubscription {}

export enum END_TREATMENT_LANGUAGE {
  ENGLISH = 1,
  HEBREW = 2,
}
export interface SubscriptionEndTreatment
  extends Pick<
    UserPatientProfileSubscription,
    'startingWeight' | 'currentWeight' | 'startingBMI' | 'currentBMI' | 'doctorRecommendation'
  > {
  language: END_TREATMENT_LANGUAGE;
  prescribedMedications: { title: string }[];
}

export interface UserSubscriptionGetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}

export interface UserSubscriptionGetActivitiesItem
  extends Pick<
    UserPatientProfileSubscription,
    'id' | 'startDate' | 'endOfTreatmentDocUrl' | 'invoiceDocUrl'
  > {
  title: Subscription['labelKey'];
  activities: number;
}

const prepareDateToCompare = (value: DateValue) => {
  return set(convertToDate(value), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
};

export const makeSchemaRefundUserPatientSubscription = (init: {
  startDate?: string;
  endDate?: string;
}) => {
  return yup.object({
    userPatientProfileID: yup.string().required('rule-required').default(''),
    userPatientProfileSubscriptionID: yup.string().required('rule-required').default(''),
    subscriptionID: yup.string().required('rule-required').default(''),
    refundAmount: yup.number().positive('rule-positive').required('rule-required').default(1),
    refundRemarks: yup.string().required('rule-required').trim().default(''),
    isCancelFutureMeetings: yup.boolean().notRequired().nullable().default(false),
    isDeactivatePatient: yup.boolean().notRequired().nullable().default(false),
    startDate: yup
      .string()
      .required('rule-required')
      .test('date-like', 'rule-date', isDateLike)
      .default('')
      .transform(transformToDateISO),
    endDate: yup
      .string()
      .required('rule-required')
      .test('date-like', 'rule-date', isDateLike)
      .test('not-more-then-end', 'rule-date-should-less-end', (date) => {
        if (!date) return true;
        if (!isDateLike(date)) return true;
        if (!init?.endDate) return true;

        const startDate = prepareDateToCompare(date);
        const endDate = prepareDateToCompare(init.endDate);

        return startDate.getTime() <= endDate.getTime();
      })
      .test('not-less-then-start', 'rule-date-should-more-start', (date) => {
        if (!date) return true;
        if (!isDateLike(date)) return true;
        if (!init?.startDate) return true;
        const endDate = prepareDateToCompare(date);
        const startDate = prepareDateToCompare(init.startDate);

        return endDate.getTime() >= startDate.getTime();
      })
      .transform(transformToDateISO)
      .default(''),
  });
};

export interface PatientSubscription
  extends Pick<UserPatientProfileSubscription, 'id' | 'startDate' | 'endDate'> {
  title: string;
  visitDietitian: boolean;
  endDate: string;
}
export interface UserPatientSubscriptionForRefund
  extends Pick<
    UserPatientProfileSubscription,
    | 'id'
    | 'userPatientProfileID'
    | 'subscriptionID'
    | 'startDate'
    | 'endDate'
    | 'subscription'
    | 'userPatientProfile'
  > {
  subscription: Pick<Subscription, 'durationMonths' | 'labelKey'>;
  userPatientProfile: Pick<Components.Schemas.UserPatientProfile, 'fullName' | 'isActive'>;
  futureClinicalIDs: string[];
  futureSupportIDs: string[];
}

export interface UserPatientSubscriptionsForLetterForMedInsurance
  extends Required<
    Pick<UserPatientProfileSubscription, 'id' | 'startDate' | 'endDate' | 'subscription'>
  > {
  subscription: Required<Pick<Subscription, 'labelKey'>>;
}
export interface UserPatientSubscriptionsForEndOfTreatment
  extends Required<
    Pick<
      UserPatientProfileSubscription,
      'id' | 'startDate' | 'endDate' | 'subscription' | 'endOfTreatmentDocUrl'
    >
  > {
  subscription: Required<Pick<Subscription, 'labelKey' | 'rowIndex'>>;
}

export interface RefundPatientSubscriptionInput
  extends InferType<ReturnType<typeof makeSchemaRefundUserPatientSubscription>> {
  futureClinicalIDs: string[];
  futureSupportIDs: string[];
}
export interface UpdatePatientSubscriptionEndDateInput {
  id: string;
  endDate: string;
  remarks: string;
  makeActive: boolean;
}
