import { AppSelect } from '../app-select';
import style from './index.module.scss';
import { AppInput, AppInputProps } from '../app-input';
import React, { useCallback } from 'react';
import { useTranslate } from '../../hooks/use-translate';

interface Props<T> {
  isLoading?: boolean;
  templates: T[];
  onSelectTemplate: (value: T) => void;
  disabledTemplate?: boolean;
}
export const AppInputTemplate = <T,>(props: Props<T> & AppInputProps) => {
  const { templates, isLoading, disabled, onSelectTemplate, disabledTemplate, ...rest } = props;
  const { tp } = useTranslate();

  const handleTemplate = useCallback(
    (_: any, option: T) => {
      onSelectTemplate(option);
    },
    //eslint-disable-next-line
    [onSelectTemplate],
  );

  return (
    <>
      <AppSelect
        className={style.template}
        label={tp('choose-a-template')}
        options={templates}
        loading={isLoading}
        disabled={disabled || disabledTemplate}
        clearOnBlur={true}
        clearOnEscape={true}
        onChange={handleTemplate}
        size={'small'}
        RenderInputProps={{
          variant: 'outlined',
          className: style.templateField,
          InputLabelProps: {
            className: style.templateLabel,
          },
          InputProps: {
            className: style.templateInput,
          },
        }}
      />
      <AppInput
        className={style.message}
        InputProps={{
          className: style.messageInput,
        }}
        variant={'outlined'}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={tp('type-your-message-here')}
        disabled={disabled}
        multiline={true}
        minRows={5}
        autoFocus
        {...rest}
      />
    </>
  );
};
