import React, { useCallback, useMemo } from 'react';
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useOpen } from 'AurionCR/components/hooks';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { AddNewDocument } from 'components/add-new-document';
import { Stack } from 'components/stack';
import style from './index.module.scss';
import { format } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import GetAppIcon from '@material-ui/icons/GetApp';
import { apiMediaPrivate } from 'services/media-private-services';
import { getFileNameExt } from 'utils/file-uploader';
import { useEffectError } from 'hooks';
import { signedDocumentEmitter } from '../../../../emitter';
import { hexToRgb } from 'components/helpers';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import clsx from 'clsx';
import { downloadBlob } from 'utils/download';

const DEFAULT_ICON = process.env.PUBLIC_URL + '/images/icon-no-signed-documents-categories.png';

interface Item {
  title: string;
  date: string;
  url: string;
  controls?: React.ReactNode;
}

const NestedList: React.FC<Item> = ({ date, title, url, controls }) => {
  const { t } = useTranslate();
  const formatedDate = useMemo(() => {
    return format(convertToDate(date), APP_FORMAT_DATE);
  }, [date]);

  const [getItem, resultGet] = apiMediaPrivate.useLazyGetMediaPrivateBlobQuery();
  useEffectError(resultGet.error);

  const onDownload = useCallback(async () => {
    const result = await getItem(url);

    if (result.data) {
      const fileName = [title, format(new Date(), APP_FORMAT_DATE), `.${getFileNameExt(url)}`]
        .filter(Boolean)
        .join(' | ');

      downloadBlob(result.data, { fileName });
    }
  }, [getItem, url, title]);

  return (
    <ListItem className={style.nested} component={'div'}>
      <ListItemText primary={title} secondary={formatedDate} />
      <Stack className={style.nestedControls}>
        {controls}
        <IconButton
          color={'primary'}
          edge="end"
          disabled={!url || resultGet.isLoading}
          onClick={onDownload}
          title={t('download')}
        >
          <GetAppIcon />
        </IconButton>
      </Stack>
    </ListItem>
  );
};

interface Props {
  categoryID: string | null;
  title: string;
  icon: string | null | undefined;
  color: string | null | undefined;
  items: Item[];
  userPatientProfileID: string;
  userPatientProfilePrescriptionID: string;
}

export const CategoryItem: React.FC<Props> = ({
  title,
  icon,
  color,
  items,
  userPatientProfileID,
  userPatientProfilePrescriptionID,
  categoryID,
}) => {
  const { t } = useTranslate();
  const { isOpen, handleToggle } = useOpen({ init: true });

  const onCreated = useCallback(() => {
    signedDocumentEmitter.emit('refresh-grid', {
      prescriptionID: userPatientProfilePrescriptionID,
    });
  }, [userPatientProfilePrescriptionID]);

  const { r, g, b } = useMemo(() => {
    return hexToRgb(String(color));
  }, [color]);
  return (
    <>
      <ListItem
        className={style.root}
        onClick={handleToggle}
        style={{ backgroundColor: `rgba( ${r}, ${g}, ${b} ,0.1)` }}
      >
        <ListItemIcon>
          <Avatar
            src={icon || DEFAULT_ICON}
            variant={'rounded'}
            style={{ padding: '0.3rem', backgroundColor: `rgba( ${r}, ${g}, ${b} ,0.5)` }}
          />
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            color: 'inherit',
            variant: 'h3',
            style: {
              color: String(color),
            },
          }}
        />
        {categoryID && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            mr={1}
          >
            <AddNewDocument
              userPatientProfileID={userPatientProfileID}
              formDocumentCategoryID={categoryID}
              postData={{
                userPatientProfilePrescriptionID,
              }}
              onDone={onCreated}
              ButtonProp={{
                size: 'small',
              }}
            />
          </Box>
        )}

        <ExpandMore className={clsx(style.icon, isOpen && style.iconActive)} />
      </ListItem>
      <Collapse in={isOpen}>
        {items.length === 0 && (
          <Stack alignItems={'center'} justifyContent={'center'} my={2}>
            <Typography color={'secondary'} variant={'h5'} component={'div'}>
              <Box textAlign={'center'}>
                <NotInterestedIcon fontSize={'large'} />
              </Box>
              {t('there-are-no-any-data')}
            </Typography>
          </Stack>
        )}
        {items.map((item, i) => {
          return <NestedList key={i} {...item} />;
        })}
      </Collapse>
    </>
  );
};
