import { GridControlsDropDownTemplateProps } from 'models/grid';
import React, { useCallback } from 'react';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import VAR from 'styles/_variabels.scss';
import { GridModel } from '../helpers';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useClone } from '../hooks';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {
  userPatientProfileSessionID: string;
}

export const ControlsDropDown: React.FC<Props> = ({
  userPatientProfileSessionID,
  onTask,
  data,
  handleClose,
}) => {
  const onSuccess = useCallback(() => {
    onTask([['getData']]);
    handleClose();
  }, [onTask, handleClose]);

  const [perform, { isLoading }] = useClone({
    userPatientProfileSessionID,
    drugID: data.drugID ?? '',
    dosageForm: data.dosageForm ?? '',
    onSuccess,
  });

  const { t } = useTranslate();

  if (data.userPatientProfileSessionID === userPatientProfileSessionID) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={perform} style={{ color: VAR.colorPrimary }} disabled={isLoading}>
        <ListItemIcon style={{ minWidth: '3rem' }}>
          <FileCopyIcon fontSize={'small'} style={{ color: VAR.colorPrimary }} />
        </ListItemIcon>
        {t('clone')}
      </MenuItem>
    </>
  );
};
