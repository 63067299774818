import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { BaseCreateComponentProps } from '../../models';
import { useEffectError, useFieldProps } from 'hooks';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { apiPdf } from 'services/pdf';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { apiPdfContent } from 'services/pdf-content';
import { isMutationFulfilled } from 'utils/rtk-query';
import { DialogEditHtmlContent } from 'components/dialog-edit-html-content';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { DialogHeading } from 'components/dialog-title';
import { Controller, useForm } from 'react-hook-form';
import { AppSelect } from 'components/app-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from 'hooks/use-translate';
import {
  useSourceBmiSummaries,
  useSourceDiagnosisTypes,
  useSourceHMOs,
} from '../../../../../hooks';
import { useUpdatePatientDataFromContent } from '../../../../hooks/use-update-patient-data-from-content';
import { AppInputTemplate } from 'components/app-input-template';
import { apiEndOfTreatmentRecommendationTemplate } from 'services/end-of-treatment-recommendation-templates';
import { Unset } from 'utils/types';
import {
  subscriptionGetLabel,
  subscriptionRenderOption,
} from 'components/select-options/subscription';
import { apiUserPatientProfileSubscriptions } from 'services/user-patient-profile-subscriptions';

const useSourceTemplatesQuery =
  apiEndOfTreatmentRecommendationTemplate.useGetEndOfTreatmentRecommendationTemplatesSourceQuery;

const useFetchSubscriptionsQuery =
  apiUserPatientProfileSubscriptions.useGetPatientSubscriptionsForEndOfTreatmentQuery;
const useGenerateContentMutation = apiPdfContent.useGenerateContentHmoLetterMutation;

const useLazyFetchPatientDetailsQuery =
  apiUserPatientProfile.useLazyGetPatientDetailsForPdfHmoLetterQuery;

const useGenerateDocumentMutation = apiPdf.useGenerateDocumentHmoLetterMutation;

const schema = yup.object({
  doctorRecommendation: yup.string().required('rule-required').default(''),

  diagnosisTypeID: yup.string().required('rule-required').default(null),
  bmiSummaryID: yup.string().required('rule-required').default(null),
  hmoID: yup.string().required('rule-required').default(null),

  userPatientProfileSubscriptionID: yup.string().required('rule-required').default(null),
});

const defaultValues = schema.cast({}, { assert: false });
type FormModel = typeof defaultValues;
type Template = { title?: Unset };

type ApplyState = { content: string; title: string };
interface DialogCreateContentProps {
  userPatientProfileID: string;
  onClose: () => void;
  onApply: (state: ApplyState) => void;
}
export const DialogCreateContent = memo<DialogCreateContentProps>(
  ({ userPatientProfileID, onClose, onApply }) => {
    const [updateUser, resultUpdateUser, patient] = useUpdatePatientDataFromContent({
      userPatientProfileID,
    });
    useEffectError(resultUpdateUser.error);

    const { tp } = useTranslate();
    const languageID = useAppSelector(selectLanguageID);

    const sourceDiagnosisTypes = useSourceDiagnosisTypes();
    const sourceBmiSummaries = useSourceBmiSummaries();
    const sourceHMOs = useSourceHMOs();

    const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
    const { errors, control, handleSubmit, setValue, getValues } = methods;
    const getFieldProps = useFieldProps({ errors, emptyHelperText: '' });

    const hmoID = patient?.hmoID ?? '';

    const hmo = sourceHMOs.map[hmoID];

    const refInit = useRef(false);
    useEffect(() => {
      if (refInit.current) return;
      if (!patient) return;

      // issue with the set form
      requestAnimationFrame(() => {
        setValue('bmiSummaryID', patient.bmiSummaryID);
        setValue('diagnosisTypeID', patient.diagnosisTypeID);
        setValue('hmoID', patient.hmoID);
      });

      refInit.current = true;
    }, [patient, setValue]);

    const [generate, resultContent] = useGenerateContentMutation();
    useEffectError(resultContent.error);

    const [triggerFetchDetails, resultDetails] = useLazyFetchPatientDetailsQuery();
    useEffectError(resultDetails.error);

    const messageRef = useRef<HTMLInputElement>(null);

    const sourceTemplates = useSourceTemplatesQuery();
    useEffectError(sourceTemplates.error);

    const onSelectTemplate = useCallback(
      (value: Template) => {
        if (!value) return;

        const message = getValues('doctorRecommendation') || '';

        setValue(
          'doctorRecommendation',
          [message.trim(), `${value.title} `].filter(Boolean).join('\n'),
        );
        messageRef.current?.focus();
      },
      [setValue, getValues],
    );

    const onGenerate = useCallback(
      async (formData: FormModel) => {
        const resUpdate = await updateUser({
          diagnosisTypeID: formData.diagnosisTypeID,
          bmiSummaryID: formData.bmiSummaryID,
          hmoID: formData.hmoID,
        });

        if (!isMutationFulfilled(resUpdate)) return;

        const resDetails = await triggerFetchDetails({
          userPatientProfileID,
          userPatientProfileSubscriptionID: String(formData.userPatientProfileSubscriptionID),
        });

        if (!resDetails.data) return;

        const res = await generate({
          languageID,
          payload: {
            subscription: {
              visitDietitian: resDetails.data.subscription?.visitDietitian,
              durationMonths: resDetails.data.subscription?.durationMonths,
              startDate: resDetails.data.subscription?.startDate,
            },
            patient: resDetails.data,
            doctorRecommendation: formData.doctorRecommendation,
          },
        });

        if (isMutationFulfilled(res)) {
          onApply(res.data);
        }
      },
      [userPatientProfileID, languageID, onApply, generate, triggerFetchDetails, updateUser],
    );

    const resultSubscriptions = useFetchSubscriptionsQuery(userPatientProfileID, {
      refetchOnMountOrArgChange: true,
    });
    useEffectError(resultSubscriptions.error);

    const isLoading =
      resultDetails.isLoading || resultContent.isLoading || resultSubscriptions.isFetching;

    const disableHmo = Boolean(hmo?.isActive === true);

    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth={'md'}>
        <DialogHeading title={tp('create-hmo-letter')} isLoading={isLoading} onClose={onClose} />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid xs={12} md={12} item>
              <Controller
                control={control}
                name={'userPatientProfileSubscriptionID'}
                render={(renderProps) => {
                  return (
                    <AppSelect
                      {...getFieldProps(renderProps, { withLabel: false })}
                      label={tp('subscription')}
                      loading={resultSubscriptions.isFetching}
                      options={resultSubscriptions.data}
                      disabled={isLoading}
                      renderOption={subscriptionRenderOption}
                      getOptionLabel={subscriptionGetLabel}
                    />
                  );
                }}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Controller
                control={control}
                name={'hmoID'}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <AppSelect
                      {...props}
                      options={sourceHMOs.data}
                      loading={sourceHMOs.loading}
                      disabled={isLoading || disableHmo}
                      disableClearable
                    />
                  );
                }}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Controller
                control={control}
                name={'diagnosisTypeID'}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <AppSelect
                      {...props}
                      options={sourceDiagnosisTypes.data}
                      loading={sourceDiagnosisTypes.loading}
                      disabled={isLoading || !!patient?.diagnosisTypeID}
                      disableClearable
                    />
                  );
                }}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Controller
                control={control}
                name={'bmiSummaryID'}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <AppSelect
                      {...props}
                      options={sourceBmiSummaries.data}
                      loading={sourceBmiSummaries.loading}
                      disabled={isLoading || !!patient?.bmiSummaryID}
                      disableClearable
                    />
                  );
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <Controller
                control={control}
                name={'doctorRecommendation'}
                render={(renderProps) => {
                  return (
                    <AppInputTemplate
                      {...getFieldProps(renderProps)}
                      inputRef={messageRef}
                      onSelectTemplate={onSelectTemplate}
                      label={tp('prescriptions')}
                      templates={sourceTemplates.data || []}
                      isLoading={sourceTemplates.isLoading}
                      disabled={isLoading}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onClose}>
            {tp('cancel')}
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            disabled={isLoading}
            onClick={handleSubmit(onGenerate)}
          >
            {tp('apply')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export const DialogCreateDocHmoLetter = memo<BaseCreateComponentProps>(
  ({ userPatientProfileID, onClose, onDone }) => {
    const [state, setState] = useState<ApplyState>();

    const [generate, resultGenerate] = useGenerateDocumentMutation();
    useEffectError(resultGenerate.error);

    const onGenerate = useCallback(
      async (content: string) => {
        if (!state) return;
        const res = await generate({ content, title: state.title, userPatientProfileID });

        if (isMutationFulfilled(res)) {
          onDone();
        }
      },
      [userPatientProfileID, onDone, generate, state],
    );

    const isLoading = resultGenerate.isLoading;

    if (!state) {
      return (
        <DialogCreateContent
          userPatientProfileID={userPatientProfileID}
          onClose={onClose}
          onApply={setState}
        />
      );
    }

    return (
      <DialogEditHtmlContent
        isLoading={isLoading}
        title={state.title}
        content={state.content}
        onGenerate={onGenerate}
        onCancel={onClose}
      />
    );
  },
);
