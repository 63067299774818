import { DateValue } from 'utils/dates';
import { UserEmployeeProfile } from '../user-employee-profiles';

const API_ENDPOINT = 'UserPatientProfileActivities';

export const API_USER_PATIENT_PROFILE_ACTIVITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<UserPatientProfileActivity, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPatientProfileActivity, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};

export class UserPatientProfileActivity implements Components.Schemas.UserPatientProfileActivity {
  id!: string;
  isActive = true;
  userEmployeeProfileID = '';
  userPatientProfileID = '';
  entryDate = new Date().toISOString();
  remarks = '';
  changes = '';
}

export interface UserPatientProfileActivityPostInput {
  userEmployeeProfileID: string;
  userPatientProfileID: string;
  remarks: string;
}

export interface GetActivitiesInput {
  userPatientProfileID: string;
  dateRange: DateValue[];
}

export interface ActivityItem
  extends Pick<UserPatientProfileActivity, 'id' | 'entryDate' | 'remarks'> {
  employee: string;
}

export interface UserPatientProfileActivityLastWeightInfo {
  entryDate: string;
  userEmployeeProfile: Pick<UserEmployeeProfile, 'fullName'>;
  remarks: string;
  changes: string;
}
