import { useTranslate } from 'hooks/use-translate';
import { useAppDispatch } from 'store';
import { apiUserPatientProfileSessionClinicDrugs } from 'services/user-patient-profile-session-clinic-drugs';
import { useEffectError } from '../../../../../hooks';
import { useCallback } from 'react';
import { isMutationRejected } from 'utils/rtk-query';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';

type UseCloneProps = {
  drugID: string;
  dosageForm: string;
  userPatientProfileSessionID: string;
  onSuccess: () => void;
};
export const useClone = (props: UseCloneProps) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const { drugID, dosageForm, userPatientProfileSessionID, onSuccess } = props;

  const [triggerClone, { error, isLoading }] =
    apiUserPatientProfileSessionClinicDrugs.usePostUserPatientProfileSessionClinicDrugsMutation();
  useEffectError(error);

  const perform = useCallback(async () => {
    const result = await triggerClone({
      userPatientProfileSessionID,
      drugID: drugID,
      dosageForm: dosageForm,
    });

    if (isMutationRejected(result)) {
      return;
    }

    onSuccess();

    dispatch(notifyRequestResult(t('success-cloned'), 'success'));
  }, [userPatientProfileSessionID, triggerClone, dispatch, t, dosageForm, drugID, onSuccess]);

  return [perform, { isLoading }] as const;
};
