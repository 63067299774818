import React, { memo, useCallback, useMemo } from 'react';
import { iEmployeeMeetingDateSelect } from './@type';
import { DialogTimeSlots } from './components';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import TodayIcon from '@material-ui/icons/Today';
import style from './index.module.scss';
import { useTranslate } from 'hooks/use-translate';
import { convertToDate } from 'utils/dates';
import { APP_FORMAT_DATE_TIME, APP_FORMAT_TIME } from 'configs/const';
import { useOpen } from 'AurionCR/components/hooks';
import { composeFunctions } from 'utils';
import { handlerStopAndPrevent } from 'utils/handlers';

type Props = iEmployeeMeetingDateSelect;

export const EmployeeMeetingDateSelect = memo<Props>(
  ({
    value,
    onChange,
    error,
    disabled,
    userEmployeeID,
    preferStartDate,
    defaultDuration,
    getPreferred,
    subscription,
  }) => {
    const { t } = useTranslate();

    // states
    const { isOpen, handleOpen, handleClose } = useOpen();
    const title = useMemo(() => {
      if (!value) return '';
      if (value.length !== 2) return '';

      return [
        format(convertToDate(value[0]), APP_FORMAT_DATE_TIME),
        format(convertToDate(value[1]), APP_FORMAT_TIME),
      ].join(' - ');
    }, [value]);

    const handleClear = useCallback(() => {
      onChange(null);
    }, [onChange]);
    const handleChange = useCallback(
      (value) => {
        onChange(value);
        handleClose();
      },
      [onChange, handleClose],
    );

    return (
      <>
        <TextField
          fullWidth
          error={Boolean(error)}
          value={title}
          label={t('meeting-datetime')}
          placeholder={`${t('click-to-set')} ${t('meeting-datetime')}`}
          helperText={(error && error?.message && t(error.message)) || ''}
          className={style.field}
          disabled={disabled}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <TodayIcon />
              </InputAdornment>
            ),
            endAdornment: title?.length ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={composeFunctions(handlerStopAndPrevent, handleClear)}
                  disabled={disabled}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
            onClick: disabled ? undefined : composeFunctions(handlerStopAndPrevent, handleOpen),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {isOpen && (
          <DialogTimeSlots
            value={value}
            userEmployeeID={userEmployeeID}
            onChange={handleChange}
            onClose={handleClose}
            preferStartDate={preferStartDate}
            defaultDuration={defaultDuration}
            getPreferred={getPreferred}
            subscription={subscription}
          />
        )}
      </>
    );
  },
);
